<template>
  <div>
    <banner-c></banner-c>
    <div class="form-wrap">

      <div class="form-wrap-title">合作咨询</div>
      <div class="form-content-m" @click.stop="null">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" require-asterisk-position="right">
          <el-form-item label="伙伴类型" prop="consultType">
            <el-select v-model="ruleForm.consultType" placeholder="请选择资源类型" class="select-default"
              popper-class="select-option">
              <el-option v-for="(item, index) in data" :key="index" :label="item.name" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item style="margin-top: 20rem;" label="姓名" prop="name">
            <el-input class="form-input" v-model="ruleForm.name" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="公司名称" prop="companyName">
            <el-input class="form-input" v-model="ruleForm.companyName" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input class="form-input" v-model="ruleForm.phone" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="留言" prop="message">
            <el-input class="form-input" type="textarea" v-model="ruleForm.message" placeholder="请输入" resize="none" />
          </el-form-item>
          <el-form-item class="form-submit">
            <div class="btn-primary" @click="submitForm(ruleFormRef)">
              提交信息
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted, reactive } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { addConsult } from '@/api'
import { ElMessage } from "element-plus";
import DropDown from '@/components/DropDown.vue'
import BannerC from '@/components/BannerC.vue';
import { asynchronousRetrieval } from '@/utils'
import { useRouter } from 'vue-router';

const router = useRouter()
const loadUrl: any = ref({})

const data = ref([
  {
    name: '硬件品牌方',
    value: 1
  },
  {
    name: '渠道端/零售终端',
    value: 2
  },
  {
    name: 'B/G端需求方',
    value: 3
  },
  {
    name: '其它',
    value: 4
  },
])
interface RuleForm {
  name: string
  companyName: string
  phone: string
  message: string
  consultType: any
}
const ruleFormRef = ref<FormInstance>()
const ruleForm: any = reactive<RuleForm>({
  name: '',
  companyName: '',
  phone: '',
  message: '',
  consultType: null
})

const verifyPhoneNumber = (rule: any, value: any, callback: any) => {
  let reg = /^(130|131|132|133|134|135|136|137|138|139|145|146|147|148|149|150|151|152|153|155|156|157|158|159|166|170|171|173|174|175|176|177|178|180|181|182|183|184|185|186|187|188|189|191|198|199)\d{8}$/
  if (!reg.test(value.replace(/\s+/g, ""))) {
    callback(new Error('请输入正确的手机号'))
  } else {
    callback()
  }
}

const rules = reactive<FormRules<RuleForm>>({
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' }
  ],
  companyName: [
    { required: true, message: '请输入公司名', trigger: 'blur', }
  ],
  phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { validator: verifyPhoneNumber, trigger: 'blur' }],
  consultType: [{ required: true, message: '请选择伙伴类型', trigger: 'blur', }],
})

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      addConsult({ ...ruleForm, appId: 1 }).then((res) => {
        ElMessage({
          message: '感谢您的反馈!',
          type: "success",
          duration: 2000,
        })
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

onMounted(() => {
  const pageQuery: any = router.currentRoute.value.query;
  ruleForm.consultType = +pageQuery.type || null;
})
</script>
<style scoped lang="less">
.form-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #F7F7F7;
  padding-top: 154rem;

  .form-wrap-title {
    position: absolute;
    top: 52rem;
    font-weight: normal;
    font-size: 40rem;
    color: #000000;
    line-height: 56rem;
  }

  .form-content-m {
    width: 534rem;
    color: #414141;
    position: relative;
    box-sizing: border-box;

    .close {
      position: absolute;
      right: 30rem;
      top: 30rem;
      width: 26rem;
      height: 26rem;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .el-input__wrapper {
      width: 458rem;
    }
  }
}
</style>