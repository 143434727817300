<template>
  <div class="cooperate">
    <img :src="loadUrl.cooperate_bg"/>
    <div class="hyst title-font-style">行业生态</div>
    <div class="logo-view">
      <div class="hzhb title-font-style">合作伙伴</div>
      <div class="level2-title">数智行业头部品牌</div>
      <img class="partners" :src="loadUrl.partners1" />
      <div class="level2-title">B端/G端合作客户</div>
      <img class="partners" :src="loadUrl.partners2" />
      <div class="open-cooperation">
        <img :src="loadUrl.open_cooperation_bg"/>
        <div class="kfhz title-font-style">开放合作</div>
        <div class="kfhz-text">欢迎携手凝丰智能 共建数智化生态</div>
        <div class="open-cooperation-box">
          <open-cooperation></open-cooperation>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import OpenCooperation from '@/components/OpenCooperation.vue';
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

onMounted(async() => {
  const partners1: any = await asynchronousRetrieval('nfznxr-images/partners-1.png');
  const partners2: any = await asynchronousRetrieval('nfznxr-images/partners-2.png');
  const cooperate_bg: any = await asynchronousRetrieval('nfznxr-images/cooperate-bg.png');
  const open_cooperation_bg: any = await asynchronousRetrieval('nfznxr-images/open-cooperation-bg.png');
  loadUrl.value = { partners1, partners2, cooperate_bg, open_cooperation_bg }
})
</script>
<style scoped lang="less">
.cooperate {
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  top: -60rem;
  padding-top: 45.5vw;
  box-sizing: border-box;
  font-family: PingFangSC-Medium;
  margin-bottom: 40rem;

  &>img {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
  }

  .level2-title {
    font-weight: 400;
    font-size: 28rem;
    color: #999999;
    margin-top: 60rem;
    margin-bottom: 40rem;
  }

  .hyst {
    color: #333333;
    position: absolute;
    top: 160rem;
    left: 50%;
    width: 300rem;
    margin-left: -150rem;
    text-align: center;
  }

  .logo-view {
    padding-top: 100rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .open-cooperation {
    position: relative;
    width: 100%;
    height: 51.1vw;
    margin-top: 100rem;

    &>img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    &>div {
      z-index: 1;
    }

    .kfhz {
      margin-top: 100rem;
      color: #FFFFFF;
    }

    .kfhz-text {
      font-weight: 400;
      font-size: 28rem;
      color: #E9E9E9;
      font-family: PingFangSC-Regular;
      margin-top: 20rem;
    }

    .open-cooperation-box {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 80rem;
    }
  }

  .partners {
    width: 1200rem;
    max-width: 88%;
  }
}
</style>