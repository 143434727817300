import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import ProductServices from "@/views/ProductServices.vue";
import AboutUs from "@/views/AboutUs.vue";
import AboutUsDetail from "@/views/AboutUsDetail.vue";
import AboutUsView from "@/views/AboutUsView.vue";
import ProductDetail from "@/views/ProductDetail.vue";
import CollaborativeView from "@/views/CollaborativeView.vue";
import ChannelExpansion from "@/views/ChannelExpansion.vue";
import SuccessCases from "@/views/SuccessCases.vue";
import SolutionView from "@/views/SolutionView.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import NewsDetail from "@/views/NewsDetail.vue";
import JobDetail from "@/views/JobDetail.vue";
import CaseDetail from "@/views/CaseDetail.vue";


// 移动端
import MobileHome from "@/views/mobile/HomeView.vue";
import MobileProductServices from "@/views/mobile/ProductServices.vue";
import MobileChannelExpansion from "@/views/mobile/ChannelExpansion.vue";
import MobileSuccessCases from "@/views/mobile/SuccessCases.vue";
import MobileCollaborativeView from "@/views/mobile/CollaborativeView.vue";
import MobileAboutUs from "@/views/mobile/AboutUs.vue";
import MobileAboutUsDetail from "@/views/mobile/AboutUsDetail.vue";
import MobileCaseDetail from "@/views/mobile/CaseDetail.vue";
import MobileNewsDetail from "@/views/mobile/NewsDetail.vue";
import MobileJobDetail from "@/views/mobile/JobDetail.vue";
import MobileAboutUsView from "@/views/mobile/AboutUsView.vue";
import MobileSolutionView from "@/views/mobile/SolutionView.vue";
import MobileCounsellingView from "@/views/mobile/CounsellingView.vue";
import MobileProductDetail from "@/views/mobile/ProductDetail.vue";


let routes: Array<RouteRecordRaw> = [];
const userAgent = navigator.userAgent.toLowerCase();

if (
  !userAgent.match(
    /(phone|pad|pod|iphone|ipod|ios|ipad|android|mobile|blackberry|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone|windows mobile|windows ce|ucweb|rv:1.2.3.4|midp)/i
  )
) {
  routes = [
    {
      path: "/home",
      name: "home",
      alias: "/",
      component: HomeView,
      meta: {
        hideTitle: true,
      },
    },
    {
      path: "/about_us_view",
      name: "about_us_view",
      component: AboutUsView,
    },
    {
      path: "/about_us",
      name: "about_us",
      component: AboutUs,
    },
    {
      path: "/about_detail",
      name: "about_detail",
      component: AboutUsDetail,
    },
    {
      path: "/product_service",
      name: "product_service",
      component: ProductServices,
    },
    {
      path: "/product_detail",
      name: "product_detail",
      component: ProductDetail,
    },
    {
      path: "/collaboratives",
      name: "collaboratives",
      component: CollaborativeView,
    },
    {
      path: "/channel_expansion",
      name: "channel_expansion",
      component: ChannelExpansion,
    },
    {
      path: "/success_cases",
      name: "success_cases",
      component: SuccessCases,
    },
    {
      path: "/solution",
      name: "solution",
      component: SolutionView,
    },
    {
      path: "/news_detail",
      name: "news_detail",
      component: NewsDetail,
    },
    {
      path: "/job_detail",
      name: "job_detail",
      component: JobDetail,
    },
    {
      path: "/case_detail",
      name: "case_detail",
      component: CaseDetail,
    },
    {
      path: "/privacy_policy",
      name: "privacy_policy",
      component: PrivacyPolicy,
    },
  ];
} else {
  routes = [
    {
      path: "/home",
      name: "home",
      alias: "/",
      component: MobileHome,
      meta: {
        hideTitle: true,
      },
    },
    {
      path: "/about_us_view",
      name: "about_us_view",
      component: MobileAboutUsView,
    },
    {
      path: "/about_us",
      name: "about_us",
      component: MobileAboutUs,
    },
    {
      path: "/about_detail",
      name: "about_detail",
      component: MobileAboutUsDetail,
    },
    {
      path: "/product_service",
      name: "product_service",
      component: MobileProductServices,
    },
    {
      path: "/product_detail",
      name: "product_detail",
      component: MobileProductDetail,
    },
    {
      path: "/collaboratives",
      name: "collaboratives",
      component: MobileCollaborativeView,
    },
    {
      path: "/channel_expansion",
      name: "channel_expansion",
      component: MobileChannelExpansion,
    },
    {
      path: "/success_cases",
      name: "success_cases",
      component: MobileSuccessCases,
    },
    {
      path: "/solution",
      name: "solution",
      component: MobileSolutionView,
    },
    {
      path: "/news_detail",
      name: "news_detail",
      component: MobileNewsDetail,
    },
    {
      path: "/job_detail",
      name: "job_detail",
      component: MobileJobDetail,
    },
    {
      path: "/case_detail",
      name: "case_detail",
      component: MobileCaseDetail,
    },
    {
      path: "/privacy_policy",
      name: "privacy_policy",
      component: PrivacyPolicy,
    },
    {
      path: "/counselling",
      name: "counselling",
      component: MobileCounsellingView,
    },
  ];
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
