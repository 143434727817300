<template>
  <div class="open-cooperation-child">
    <div class="shuzhi-form">
      <img :src="loadUrl.shuzhi" />
      <div class="text">我是数智产品品牌方</div>
      <div @click="router.push('/counselling?type=1')">合作咨询</div>
    </div>
    <div class="shuzhi-form">
      <img :src="loadUrl.zhongduan" />
      <div class="text">我是渠道端/零售终端</div>
      <div @click="router.push('/counselling?type=2')">合作咨询</div>
    </div>
    <div class="shuzhi-form">
      <img :src="loadUrl.Bduan" />
      <div class="text">我是B/G端需求方</div>
      <div @click="router.push('/counselling?type=3')">合作咨询</div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, watch, onMounted } from 'vue'
import { asynchronousRetrieval } from '@/utils'
import { useRouter } from 'vue-router';

const router = useRouter()
const loadUrl: any = ref({})

onMounted(async () => {
  const shuzhi: any = await asynchronousRetrieval('nfznxr-images/shuzhi.png');
  const zhongduan: any = await asynchronousRetrieval('nfznxr-images/zhongduan.png');
  const Bduan: any = await asynchronousRetrieval('nfznxr-images/Bduan.png');
  loadUrl.value = { shuzhi, zhongduan, Bduan }
})
</script>
<style scoped lang="less">
.open-cooperation-child {
  position: relative;
  width: 1839rem;
  height: 938rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 36rem;

  &>div {
    width: 601rem;
    height: 938rem;
    position: relative;
    font-weight: 600;
    color: #333333;
    font-family: PingFangSC-Medium;

    img {
      width: 100%;
      height: 100%;
    }

    &>div {
      position: relative;
      position: absolute;
      width: 100%;
      left: 0;
      top: 84rem;
      font-size: 40rem;
    }

    &>div:last-child {
      position: absolute;
      left: 50%;
      top: auto;
      bottom: 114rem;
      width: 224rem;
      height: 64rem;
      background: #D81418;
      border-radius: 8rem;
      margin-left: -112rem;
      font-weight: 500;
      font-size: 32rem;
      color: #FFFFFF;
      line-height: 64rem;
      text-align: center;
    }
  }
}
</style>