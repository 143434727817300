<template>
  <div class="education">
    <div class="title-font-style">军警部队解决方案</div>
    <div class="illustrate">
      <div>VR技术在军事领域可以模拟真实战斗环境，使受训士兵“真正”进入形象逼真的战场，从而增强临场反应能力，减少人员损伤，大大提高训练质量。</div>
    </div>
    <div class="subtitle">方案优势</div>
    <div class="fays">
      <img :src="loadUrl.fays_bg"/>
      <div>
        <div>
          <img src="@/assets/images/jjfa/advantage1.png" />
          <div>直观全面地反映演习的动态和交互，环境逼真。</div>
        </div>
        <div>
          <img src="@/assets/images/jjfa/advantage2.png" />
          <div>降低了演习的时间、空间和地域的限制。</div>
        </div>
        <div>
          <img src="@/assets/images/jjfa/advantage3.png" />
          <div>使用方便，训练成本低。</div>
        </div>
        <div>
          <img src="@/assets/images/jjfa/advantage4.png" />
          <div>减少人员损伤，提升训练质量。</div>
        </div>
      </div>
    </div>
    <div class="yycj">
      <div class="subtitle">应用场景</div>
      <div class="yycj-content">
        <div>
          <img :src="loadUrl.yycj_img11"
            class="left-radius" />
          <div>虚拟战场</div>
        </div>
        <div class="jiaoyu">
          <div>武器装备操作训练</div>
          <img :src="loadUrl.yycj_img12"
            class="right-radius" />
        </div>
        <div>
          <img :src="loadUrl.yycj_img13"
            class="left-radius" />
          <div>灾情救援仿真模拟</div>
        </div>
        <div class="jiaoyu">
          <div>单兵作战，远程协助作战演练</div>
          <img :src="loadUrl.yycj_img14"
            class="right-radius" />
        </div>
      </div>
    </div>
    <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div
          @click="toOtherPath(`/case_detail?id=${casesList.id}&pathName=解决方案,军警部队解决方案,应用案例`, ['解决方案', '军警部队解决方案', '应用案例'])">
          <img :src="casesList.ossPath" />
          <div class="content">
            <div class="title">{{ casesList.title }}</div>
            <div>{{ casesList.subTitle }}</div>
            <div class="detail">
              {{ casesList.introduction }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const store = useStore()
const router = useRouter()
const casesList: any = ref({})

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(async () => {
  selectCommonElement({ columnId: 4 }).then((res: any) => {
    casesList.value = [...res].filter(e => e.industry == 5)[0] || {}
  })

  const yycj_img11: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img11.png');
  const yycj_img12: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img12.png');
  const yycj_img13: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img13.png');
  const yycj_img14: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img14.png');
  const fays_bg: any = await asynchronousRetrieval('nfznxr-images/jjfa/fays-bg.png');
  loadUrl.value = { yycj_img11, yycj_img12, yycj_img13, yycj_img14, fays_bg }
})
</script>
<style scoped lang="less">
.education {
  background-color: #F7F7F7;
  padding-top: 100rem;
  overflow: hidden;

  .illustrate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40rem 0 80rem;

    &>div {
      font-weight: 400;
      font-size: 18rem;
      color: #333333;
      width: 886rem;
      line-height: 30rem;
    }
  }

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 28rem;
    color: #333333;
    line-height: 40rem;
    display: inline-block;
    padding: 0 10rem;

    &::after {
      content: '';
      width: 100%;
      height: 13rem;
      background: rgba(215, 24, 25, 0.21);
      position: absolute;
      left: 0;
      bottom: 3rem;
    }
  }

  .hytd-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 80rem;

    .hytd {
      width: 1204rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &>div {
        width: 394rem;
        font-size: 0;

        &>div {
          padding: 30rem 29rem;
          font-weight: 400;
          font-size: 16rem;
          color: #5C5C5C;
          line-height: 30rem;
          text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
          text-align: left;
          height: 200rem;
          box-sizing: border-box;
          background: #FFFFFF;
          box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 0 0 8rem 8rem;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30px;
            text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
            font-family: PingFangSC-Medium;
            margin-bottom: 15rem;

            img {
              width: 47rem;
              height: 46rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }

      .images {
        width: 393rem;
        height: 330rem;
        background: #F2F4FA;
        box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 8rem 8rem 0 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0;

        img {
          width: 229rem;
          height: 250rem;
        }
      }
    }
  }

  .fays {
    position: relative;
    width: 100%;
    height: 530rem;
    margin-top: 80rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100rem;
    text-align: left;

    &>img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index:0;
    }

    &>div {
      width: 1200rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &>div {
        width: 292rem;
        height: 330rem;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 8rem;
        backdrop-filter: blur(10rem);
        margin: 0 5rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-weight: 400;
        font-size: 16rem;
        color: #333333;
        line-height: 30rem;
        text-align: center;

        img {
          width: 100%;
          height: auto;
          margin-bottom: 31rem;
        }

        &>div {
          padding: 0 28rem;
        }
      }
    }
  }

  .yycj {
    background-color: #fff;
    padding-top: 60rem;

    .yycj-content {
      margin-top: 80rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &>div {
        width: 1201rem;
        background: #F7F7F7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8rem;
        margin-bottom: 10rem;

        &>img {
          width: 974rem;
          height: 280rem;
        }

        &>div {
          display: inline-block;
          width: 222rem;
          height: 280rem;
          text-align: center;
          color: #333333;
          font-size: 22rem;
          line-height: 30rem;
          font-weight: 600;
          font-family: PingFangSC-Medium;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 20rem;
          box-sizing: border-box;
        }
      }

      &>div:last-child {
        margin-bottom: 0;
      }
    }
  }

  .yyal {
    padding-top: 80rem;
    background-color: #fff;
    overflow: hidden;

    .yyal-content {
      margin: 80rem 0 40rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 1040rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60rem;
        padding: 20rem;
        border-radius: 8rem;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(215, 24, 25, 0.2), 0px 0px 4px 0px rgba(215, 24, 25, 0.2);
        }

        img {
          width: 400rem;
          height: 224rem;
        }

        .content {
          width: 607rem;
          height: 224rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 24rem;
            color: #0A0A0A;
            line-height: 33px;
            margin: 8rem 0 9rem;
          }

          .detail {
            font-weight: 400;
            font-size: 14rem;
            color: #333333;
            line-height: 30rem;
            margin-top: 20rem;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }
  }
}
</style>