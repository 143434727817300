<template>
  <div>
    <div class="banner-bg">
      <img :src="bannerList[0]" v-if="bannerList.length == 1" />
      <el-carousel height="100%" :pause-on-hover="false" :interval="10000" v-if="bannerList.length != 1"
        :indicator-position="store.state.userAgent == 'mobile' ? 'none' : ''">
        <el-carousel-item v-for="url in bannerList" :key="url">
          <img :src="url" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home">
      <div class="home-content">
        <div class="szcp title-font-style">数智产品</div>
        <div class="first-level-title">
          <div v-for="(item, id) in productTypes" :class="activeType == id ? 'active' : ''" :key="id"
            @click="selectType(id)">
            {{ item.name }}
          </div>
        </div>
        <div class="secondary-title">
          <div v-if="productTypes[activeType]?.value">
            <span :class="activeProduct == item.id ? 'active' : ''"
              v-for="(item, index) in productTypes[activeType].value" :key="index" @click="selectProduct(item.id)">{{
                item.brandName
              }}</span>
          </div>
        </div>
        <div class="product-child-box" v-if="showProducts.length">
          <div v-for="(e, i) in showProducts" :key="i"
            @click="toOtherPath(`/product_detail?id=${e.id}&pathName=数智产品,${e.productTypeName},${e.productBrandName},${e.productName}`, 1, ['数智产品', e.productTypeName, e.productBrandName, e.productName])">
            <div class="product-img">
              <img :src="e.picList[0]" />
            </div>
            <div class="product-text">
              <div class="product-title">{{ e.productName }}</div>
              <div>{{ e.productIntroduction }}</div>
            </div>
          </div>
          <div class="more-box">
            <div @click="toOtherPath('/product_service?pathName=产品服务,数智产品', 1, ['产品服务', '数智产品'])">
              <span>更多产品</span>
              <img src="/images/right-red-fine.png" />
            </div>
          </div>
        </div>
        <div class="qdfg">
          <div class="title-font-style">渠道覆盖</div>
          <div class="level2-title">携手数智领域头部品牌 抢滩销量新通路</div>
          <img :src="loadUrl.qdfg" />
        </div>
        <div class="jjfa">
          <div class="title-font-style">解决方案</div>
          <div class="jjfa-detail"
            :style="{ background: `url(${solutions[activeSolutionsIndex].url})`, backgroundSize: '100% 100%' }"
            @click.stop="changeRouter">
            <div class="jjfa-list-box" @click.stop="null">
              <div v-for="(item, index) in solutions" :key="index"
                :class="activeSolutionsIndex == index ? 'active-j' : ''" @click="activeSolutionsIndex = index">
                <div class="red-line" v-if="activeSolutionsIndex == index"></div>
                <div>{{ item.name }}</div>
                <img src="/images/for-right.png" v-if="activeSolutionsIndex == index" />
                <div class="jjfa-list-text" v-if="activeSolutionsIndex == index">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="cgal">
          <div class="title-font-style">成功案例</div>
          <div class="cgal-window" @mouseenter.stop="stopMove = true" @mouseleave.stop="stopMove = false">
            <div class="cgal-img-list" @mouseenter.stop="null" @mouseleave.stop="null">
              <div class="box1"
                @click="router.push('/case_detail?htmlId=24&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                <img :src="loadUrl.cgal_img1" />
              </div>
              <div class="box2">
                <div
                  @click="router.push('/case_detail?htmlId=21&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                  <img :src="loadUrl.cgal_img2" />
                </div>
                <div
                  @click="router.push('/case_detail?htmlId=22&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                  <img :src="loadUrl.cgal_img3" />
                </div>
              </div>
              <div class="box3"
                @click="router.push('/case_detail?htmlId=14&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                <img :src="loadUrl.cgal_img4" />
              </div>
              <div class="box4">
                <div
                  @click="router.push('/case_detail?htmlId=18&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                  <img :src="loadUrl.cgal_img5" />
                </div>
                <div
                  @click="router.push('/case_detail?htmlId=20&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                  <img :src="loadUrl.cgal_img6" />
                </div>
              </div>
              <div class="box5"
                @click="router.push('/case_detail?htmlId=26&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                <img :src="loadUrl.cgal_img7" />
              </div>
              <div class="box6">
                <div
                  @click="router.push('/case_detail?htmlId=31&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                  <img :src="loadUrl.cgal_img8" />
                </div>
                <div
                  @click="router.push('/case_detail?htmlId=25&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                  <img :src="loadUrl.cgal_img9" />
                </div>
              </div>
              <div class="box1"
                @click="router.push('/case_detail?htmlId=24&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                <img :src="loadUrl.cgal_img1" />
              </div>
              <div class="box2">
                <div
                  @click="router.push('/case_detail?htmlId=21&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                  <img :src="loadUrl.cgal_img2" />
                </div>
                <div
                  @click="router.push('/case_detail?htmlId=22&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                  <img :src="loadUrl.cgal_img3" />
                </div>
              </div>
              <div class="box3"
                @click="router.push('/case_detail?htmlId=14&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                <img :src="loadUrl.cgal_img4" />
              </div>
              <div class="box4">
                <div
                  @click="router.push('/case_detail?htmlId=18&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                  <img :src="loadUrl.cgal_img5" />
                </div>
                <div
                  @click="router.push('/case_detail?htmlId=20&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                  <img :src="loadUrl.cgal_img6" />
                </div>
              </div>
              <div class="box5"
                @click="router.push('/case_detail?htmlId=26&pathName=解决方案,应用案例,案例详情'); store.state.pageTitle = ['解决方案', '应用案例', '案例详情']">
                <img :src="loadUrl.cgal_img7" />
              </div>
            </div>
          </div>
        </div>
        <div class="kfhz">
          <div class="title-font-style">开放合作</div>
          <div class="open-cooperation-box">
            <open-cooperation></open-cooperation>
          </div>
        </div>
        <div class="xwzx">
          <div class="title-font-style">新闻中心</div>
          <div class="more-box">
            <div>
              <span @click="toOtherPath(`/about_us?pathName=关于我们,新闻动态`, 5, ['关于我们', '新闻动态'])">更多新闻</span>
              <img src="/images/right-red-fine.png" />
            </div>
          </div>
          <div class="xwzx-box">
            <div
              @click="toOtherPath(`/news_detail?id=${newsList[0].id}&pathName=关于我们,新闻动态,新闻详情`, 5, ['关于我们', '新闻动态', '新闻详情'])">
              <div v-if="newsList[0]">{{ newsList[0].title || '' }}</div>
              <img v-if="newsList[0]" :src="newsList[0].ossPath || ''" />
            </div>
            <div>
              <div class="news-title">最新快讯</div>
              <div class="news-content">
                <div v-for="(e, i) in newsList" :key="i"
                  @click="toOtherPath(`/news_detail?id=${e.id}&pathName=关于我们,新闻动态,新闻详情`, 5, ['关于我们', '新闻动态', '新闻详情'])">
                  <div>{{ e.title }}</div>
                  <div>{{ e.createTime }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, onMounted } from 'vue'
import { getProductTypes, addConsult, getProducts, selectCommonElement, getProductProfile, getProductList } from '@/api'
import OpenCooperation from '@/components/OpenCooperation.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const bannerList = ref([])
const router = useRouter()
const store = useStore()
const newsList: any = ref([])
const showProducts: any = ref([])
const stopMove = ref(false)


const productTypes: any = ref({})
// const activeProductIndex = ref(0)
const activeSolutionsIndex = ref(0)
const activeType = ref(1)
const activeProduct = ref(0)
let time = 0

// 解决方案枚举
const solutions: any = ref(
  [
    {
      name: "文娱",
      content:
        "全景景区导览、虚拟博物馆、虚实融合AR项目应用、商场儿童游乐项目体验、企业展厅、营销展示、虚拟数字人、虚拟直播",
    },
    {
      name: "工业",
      content:
        "设计评审、虚拟装配、维修维护、远程协助、技能培训、数字孪生、点巡检、工厂漫游",
    },
    {
      name: "教育",
      content:
        "基础教育/高等教育/职业教育教学、播控、创新实验室建设、创新课堂建设、企业培训",
    },
    {
      name: "医疗",
      content: "医疗培训、康复治疗、医学科普",
    },
    {
      name: "军警部队",
      content: "虚拟战场演习、武器装备操作训练、灾情救援仿真模拟",
    },
  ]
);

// const activeProductList = (id: number) => {
//   activeProductIndex.value = id
//   getProductsData(id)
// }

const toTop = () => {
  window.scrollTo(0, 0)
}

const toOtherPath = (url: string, titleIndex: number, pageTitle: any) => {
  router.push(url);
  store.state.titleIndex = titleIndex;
  store.state.pageTitle = [...pageTitle]
}

const selectType = (id: number) => {
  activeType.value = id;
  activeProduct.value = 0
  getProductsData(id)
}

const selectProduct = (id: number) => {
  activeProduct.value = id
  getProductsData(activeType.value, id)
}

const changeRouter = () => {
  const solutions = ['文娱解决方案', '工业解决方案', '教育解决方案', '医疗解决方案', '军警部队解决方案']
  router.push(`/solution?type=${activeSolutionsIndex.value}&pathName=解决方案,${solutions[activeSolutionsIndex.value]}`)
  store.state.programmeIndex = activeSolutionsIndex.value
  toTop()
}

onMounted(async () => {
  getProductTypes().then((res: any) => {
    getProductProfile().then((res2: any) => {
      // activeProduct.value = res2[0].brandProfileList[0].id
      res2.forEach((ele: any) => {
        if (!productTypes.value[ele.productTypeId]) {
          productTypes.value[ele.productTypeId] = { name: res[ele.productTypeId], value: [...(ele.brandProfileList || [])] }
        } else {
          productTypes.value[ele.productTypeId].push({ name: res[ele.productTypeId], value: [...(ele.brandProfileList || [])] })
        }
      });
      getProductsData(activeType.value, activeProduct.value)
    })
  })
  selectCommonElement({ columnId: 5 }).then((res: any) => {
    newsList.value = res.slice(0, 4).map((e: any) => {
      const date = new Date(e.createTime)
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      const h = date.getHours();
      const mx = date.getMinutes();
      const s = date.getSeconds();
      e.createTime = `${y}-${m}-${d} ${h}:${mx}:${s}`
      return e;
    })
  })

  selectCommonElement({ columnId: 1 }).then((res: any) => {
    bannerList.value = res.map((e: any) => e.ossPath)
  })

  function animate() {
    if (!document.querySelector('.cgal-img-list')) return;
    if (!stopMove.value) {
      const oldLeft = (document.querySelector('.cgal-img-list') as any)?.getBoundingClientRect().left;
      if (time == 0) {
        (document.querySelector('.cgal-img-list') as any).style.left = (oldLeft - 0.3) + 'px';
      } else {
        if (oldLeft <= -(window.innerWidth / 1920) * 2766) {
          (document.querySelector('.cgal-img-list') as any).style.left = '0px'
        } else {
          (document.querySelector('.cgal-img-list') as any).style.left = (oldLeft - 0.3 * ((new Date().getTime() - time) / 10)) + 'px';
        }
      }
    }
    // 使用requestAnimationFrame循环动画
    time = new Date().getTime()
    requestAnimationFrame(animate);
  }
  requestAnimationFrame(animate);
  if (store.state.userAgent == 'mobile') {
    (document.querySelector('.cgal-window') as any).style.overflow = 'hidden'
  }

  const qdfg: any = await asynchronousRetrieval('nfznxr-images/qdfg.png');
  const cgal_img1: any = await asynchronousRetrieval('nfznxr-images/cgal/img1.png');
  const cgal_img2: any = await asynchronousRetrieval('nfznxr-images/cgal/img2.png');
  const cgal_img3: any = await asynchronousRetrieval('nfznxr-images/cgal/img3.png');
  const cgal_img4: any = await asynchronousRetrieval('nfznxr-images/cgal/img4.png');
  const cgal_img5: any = await asynchronousRetrieval('nfznxr-images/cgal/img5.png');
  const cgal_img6: any = await asynchronousRetrieval('nfznxr-images/cgal/img6.png');
  const cgal_img7: any = await asynchronousRetrieval('nfznxr-images/cgal/img7.png');
  const cgal_img8: any = await asynchronousRetrieval('nfznxr-images/cgal/img8.png');
  const cgal_img9: any = await asynchronousRetrieval('nfznxr-images/cgal/img9.png');
  const jjfa_img1: any = await asynchronousRetrieval('nfznxr-images/jjfa/img1.png');
  const jjfa_img2: any = await asynchronousRetrieval('nfznxr-images/jjfa/img2.png');
  const jjfa_img3: any = await asynchronousRetrieval('nfznxr-images/jjfa/img3.png');
  const jjfa_img4: any = await asynchronousRetrieval('nfznxr-images/jjfa/img4.png');
  const jjfa_img5: any = await asynchronousRetrieval('nfznxr-images/jjfa/img5.png');
  loadUrl.value = { qdfg, cgal_img1, cgal_img2, cgal_img3, cgal_img4, cgal_img5, cgal_img6, cgal_img7, cgal_img8, cgal_img9, jjfa_img1, jjfa_img2, jjfa_img3, jjfa_img4, jjfa_img5 }

  solutions.value[0].url = jjfa_img1
  solutions.value[1].url = jjfa_img2
  solutions.value[2].url = jjfa_img3
  solutions.value[3].url = jjfa_img4
  solutions.value[4].url = jjfa_img5
})
const getProductsData = (productTypeId?: number | string, brandId?: number | string) => {
  getProducts({ productTypeId, brandId }).then((res: any) => {
    showProducts.value = res.records.slice(0, 4)
  })
}

</script>
<style scoped lang="less">
.banner-bg {
  width: 100vw;
  height: 745rem;
  background-size: 100% 100%;
  position: relative;
  top: -60rem;

  &>div {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.banner-bg>img {
  width: 100%;
  height: 100%;
}

.home {
  width: 100%;
  text-align: center;

  .home-content {
    width: 100%;
    display: inline-block;
    margin-top: -60rem;
    padding-top: 100rem;
    background-color: #F7F7F7;

    .change-title {
      height: 60rem;
      background-color: #fff;
      margin: 40rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 20rem;
      color: #333333;

      &>div {
        height: 60rem;
        line-height: 60rem;
        padding: 0 11rem;
        margin: 0 39rem;
        cursor: pointer;
      }

      .active-p {
        position: relative;
        font-family: PingFangSC-Medium;
        font-weight: 800;
        color: #D81417;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: #D81417;
        }
      }
    }

    .product-child-box {
      position: relative;
      padding-bottom: 105rem;
      width: 1208rem;
      font-size: 0;
      display: inline-block;
      text-align: left;

      &>div {
        width: 292rem;
        height: 436rem;
        background: #fff;
        border-radius: 4rem;
        padding: 10rem 10rem 30rem 10rem;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        margin-left: 5rem;
        margin-right: 5rem;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(215, 24, 25, 0.2), 0px 0px 4px 0px rgba(215, 24, 25, 0.2);
        }

        .product-img {
          width: 272rem;
          height: 272rem;
          background: #F7F7F7;
          border-radius: 4rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
          }

          &:hover img {
            transform: scale(1.28);
          }
        }

        .product-text {
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 14rem;
          color: #666666;
          text-align: left;

          .product-title {
            font-weight: bold;
            font-size: 18rem;
            color: #333333;
            text-align: center;
            margin-top: 23rem;
            margin-bottom: 10rem;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
          }

          &>div:last-child {
            width: 100%;
            height: 60rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5;
          }
        }
      }

      .more-box {
        width: 1201rem;
        position: absolute;
        left: 50%;
        bottom: 40rem;
        margin-left: -600.5rem;
        height: 25rem;
        line-height: 25rem;
        padding: 0;
        text-align: right;
        font-weight: 400;
        font-size: 18rem;
        color: #D81417;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: transparent;
        cursor: default;

        &:hover {
          box-shadow: none;
        }

        &>div {
          cursor: pointer;

          span {
            vertical-align: middle;
          }
        }

        img {
          width: 6rem;
          height: 11rem;
          margin-left: 6rem;
          vertical-align: middle;
          margin-bottom: 2rem;
        }
      }
    }

    .qdfg {
      background-color: #fff;
      padding-top: 100rem;
      padding-bottom: 46rem;

      .level2-title {
        font-weight: 400;
        font-size: 28rem;
        color: #999999;
        margin-top: 20rem;
        margin-bottom: 50rem;
      }

      img {
        width: 1208rem;
        height: 503rem;
      }
    }

    .jjfa {
      padding: 100rem 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .jjfa-detail {
        position: relative;
        margin-top: 80rem;
        width: 1200rem;
        height: 575rem;
        cursor: pointer;

        .jjfa-list-box {
          width: 196rem;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.3);
          border-radius: 8rem 0 0 8rem;
          backdrop-filter: blur(10px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          padding: 40rem 0 40rem 36rem;
          box-sizing: border-box;
          cursor: default;

          &>div {
            height: 42rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: 600;
            font-size: 22rem;
            color: rgba(255, 255, 255, 0.5);
            // background-color: pink;
            padding-left: 6rem;
            cursor: pointer;

            .red-line {
              width: 6rem;
              height: 42rem;
              background-color: #D71819;
              margin-right: 10rem;
            }

            img {
              width: 32rem;
              height: 32rem;
              margin-left: 10rem;
            }

            .jjfa-list-text {
              width: 974rem;
              position: absolute;
              bottom: 30rem;
              left: 226rem;
              font-weight: 600;
              font-size: 20rem;
              color: #FFFFFF;
              text-align: left;
              padding-right: 30rem;
              box-sizing: border-box;
            }
          }

          .active-j {
            padding-left: 0;
            color: #fff;
          }
        }

        &::before {
          content: '';
          width: 1004rem;
          height: 88rem;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
          border-radius: 0 0 8rem 0;
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }

    .cgal {
      background-color: #fff;
      padding-top: 100rem;
      position: relative;
      height: 824rem;

      .cgal-window {
        width: 1920rem;
        height: 560rem;
        position: relative;
        position: absolute;
        top: 264rem;

        .cgal-img-list {
          width: 5090rem;
          // width: 3680rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: absolute;
          left: 80rem;

          .box1 {
            width: 480rem;
            height: 560rem;
            background-position: center center;
            /* 水平居中和垂直居中 */

            &::after {
              content: '虚实互动康复医疗';
              position: absolute;
              left: 30rem;
              top: 30rem;
            }
          }

          .box1,
          .box3,
          .box5 {
            position: relative;
            border-radius: 8rem;
            cursor: pointer;
            font-weight: 600;
            font-size: 24rem;
            color: #FFFFFF;

            &:hover {
              box-shadow: 0 0 12rem 0 rgba(215, 24, 25, 0.4);
              background-size: 128% 128%;
              background-position: center center;
              /* 水平居中和垂直居中 */
            }

            &>img {
              width: 100%;
              height: 100%;
            }
          }

          .box2,
          .box4,
          .box6 {
            width: 422rem;
            height: 560rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-weight: 600;
            font-size: 24rem;
            color: #FFFFFF;

            &>div {
              position: relative;
              width: 422rem;
              height: 275rem;
              border-radius: 8rem;
              cursor: pointer;

              &:hover {
                box-shadow: 0 0 12rem 0 rgba(215, 24, 25, 0.4);
                background-size: 128% 128% !important;
                background-position: center center !important;
                /* 水平居中和垂直居中 */
              }

              &>img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .box2 {
            &>div:first-child {
              background-position: center center;
              /* 水平居中和垂直居中 */

              &::after {
                content: '数智财会产教融合实践基地';
                position: absolute;
                left: 30rem;
                top: 30rem;
              }
            }

            &>div:last-child {
              background-position: center center;
              /* 水平居中和垂直居中 */

              &::after {
                content: 'AR技术革新博物馆体验';
                position: absolute;
                left: 30rem;
                top: 30rem;
              }
            }
          }

          .box3 {
            width: 480rem;
            height: 560rem;
            background-position: center center;
            /* 水平居中和垂直居中 */

            &::after {
              content: 'VR航空救援协同演练';
              position: absolute;
              left: 30rem;
              top: 30rem;
            }
          }

          .box4 {
            &>div:first-child {
              background-position: center center;
              /* 水平居中和垂直居中 */

              &::after {
                content: '上市公司元宇宙科技展厅';
                position: absolute;
                left: 30rem;
                top: 30rem;
              }
            }

            &>div:last-child {
              background-position: center center;
              /* 水平居中和垂直居中 */

              &::after {
                content: '上海美罗城商场互动营销';
                position: absolute;
                left: 30rem;
                top: 30rem;
              }
            }
          }

          .box5 {
            width: 480rem;
            height: 560rem;
            background-position: center center;
            /* 水平居中和垂直居中 */

            &::after {
              content: 'AR数字空间展';
              position: absolute;
              left: 30rem;
              top: 30rem;
            }
          }

          .box6 {
            &>div:first-child {
              background-position: center center;
              /* 水平居中和垂直居中 */

              &::after {
                content: '大型工业设备在线实时培训';
                position: absolute;
                left: 30rem;
                top: 30rem;
              }
            }

            &>div:last-child {
              background-position: center center;
              /* 水平居中和垂直居中 */

              &::after {
                content: '虚拟现实重构警察培训';
                position: absolute;
                left: 30rem;
                top: 30rem;
              }
            }
          }
        }
      }
    }

    .kfhz {
      background-color: #fff;

      .open-cooperation-box {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 80rem;
      }
    }

    .xwzx {
      background-color: #fff;
      padding-top: 100rem;
      padding-bottom: 200rem;

      .more-box {
        font-weight: 400;
        font-size: 18rem;
        color: #D81417;
        font-family: PingFangSC-Medium;
        margin-top: 20rem;

        &>div {
          display: inline-block;
          cursor: pointer;
        }

        span {
          vertical-align: middle;
        }

        img {
          width: 6rem;
          height: 11rem;
          vertical-align: middle;
          margin-left: 6rem;
        }

        padding-bottom: 73rem;
      }

      .xwzx-box {
        width: calc(843rem + 17vw);
        height: 400rem;
        margin-left: calc(41.5vw - 421.5rem);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        &>div:first-child {
          position: relative;
          width: 680rem;
          height: 400rem;
          background-color: #efefef;
          border-radius: 8rem;
          overflow: hidden;
          cursor: pointer;

          &>div {
            width: 100%;
            height: 75rem;
            line-height: 75rem;
            position: absolute;
            left: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(66, 64, 64, 0) 0%, #1F1E1E 100%);
            border-radius: 0 0 8rem 8rem;
            padding-left: 16rem;
            box-sizing: border-box;
            font-weight: 600;
            font-size: 22rem;
            color: #FFFFFF;
            text-align: left;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        &>div:last-child {
          width: 443rem;
          height: 400rem;
          // background-color: #efefef;
          padding-top: 7rem;
          box-sizing: border-box;

          .news-title {
            position: relative;
            font-weight: 600;
            font-size: 24rem;
            color: #333333;
            font-family: PingFangSC-Medium;
            text-align: left;
            padding-left: 56rem;
            padding-bottom: 16rem;
            border-bottom: 1px solid #CBCBCB;
            margin-bottom: 40rem;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 6rem;
              width: 36rem;
              height: 24rem;
              background: url(/public/images/news.png);
              background-size: 100% 100%;
            }
          }

          .news-content {
            &>div {
              height: 52rem;
              margin-bottom: 32rem;
              text-align: left;
              font-family: PingFangSC-Medium;
              font-weight: 500;
              font-size: 16rem;
              cursor: pointer;

              &>div:first-child {
                color: #000000;
              }

              &>div:last-child {
                color: #C2C2C2;
                // padding-left: 11rem;
                margin-top: 8rem;
              }
            }
          }
        }
      }
    }

    .first-level-title {
      height: 60rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      margin-top: 40rem;

      &>div {
        position: relative;
        height: 60rem;
        line-height: 60rem;
        font-size: 20rem;
        margin: 0 50rem;
        cursor: pointer;
        font-weight: 400;
        text-align: center;
      }

      .active {
        font-weight: 800;
        color: #D81417;

        &::after {
          content: '';
          width: 51rem;
          height: 2rem;
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -25.5rem;
          background-color: #D81417;
        }

        &::before {
          content: '';
          width: 12rem;
          height: 8rem;
          background: url(/public/images/top-red.png);
          background-size: 100% 100%;
          position: absolute;
          right: -23rem;
          top: 50%;
          margin-top: -4rem;
        }
      }
    }

    .secondary-title {
      height: 60rem;
      background: rgba(0, 0, 0, 0.03);
      font-weight: 400;
      font-size: 18rem;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 40rem;

      &>div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC-Regular;

        span {
          cursor: pointer;
          margin: 0 40rem;
        }

        .active {
          font-weight: 800;
          color: #D81417;
        }
      }
    }
  }
}
</style>