<template>
  <div class="bottom-box">
    <div class="bottom-content">
      <div class="bottom-wrap">
        <div>
          <div class="bottom-logo">
            <img src="@/assets/images/bottom-logo.png" />
          </div>
          <div class="bottom-codes">
            <div>
              <img src="@/assets/images/code1.png" />
              <div>公众号</div>
            </div>
            <div>
              <img src="@/assets/images/code2.png" />
              <div>视频号</div>
            </div>
          </div>
        </div>
        <div class="bottom-right">
          <div v-for="(item, index) in linkList" :key="index">
            <div class="title">{{ item.title }}</div>
            <div class="url-list" v-for="(e, i) in item.value" :key="i" :class="e.name == '市场/媒体' ? 'blod' : ''"
              @click="changeRouter(e, e.titleIndex)">{{ e.name }}</div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="filings">
        <div>上海市徐汇区钦州北路1001号光启园7号楼6层</div>
        <div><span @click="router.push('/privacy_policy');">法律声明及隐私政策</span> <span style="color: #fff;"
            @click="to1('https://beian.miit.gov.cn/#/Integrated/index')">沪ICP备2023012402号</span></div>
        <div><span style="color: #fff;"
            @click="to1('https://beian.mps.gov.cn/#/query/webSearch')">沪公网安备31010402010107号</span></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { asynchronousRetrieval } from '@/utils'

const store = useStore()
const router = useRouter()
const linkList: any = [
  {
    title: '产品服务',
    value: [
      {
        name: '数智产品',
        path: '/product_service',
        titleIndex: 1,
        pageTitle: ['产品服务', '数智产品']
      },
      {
        name: '渠道拓展',
        path: '/channel_expansion',
        titleIndex: 1,
        pageTitle: ['产品服务', '渠道拓展']
      }
    ]
  },
  {
    title: '解决方案',
    value: [
      {
        name: '文娱解决方案',
        path: '/solution',
        index: 0,
        titleIndex: 2,
        pageTitle: ['解决方案', '文娱解决方案']
      },
      {
        name: '工业解决方案',
        path: '/solution',
        index: 1,
        titleIndex: 2,
        pageTitle: ['解决方案', '工业解决方案']
      },
      {
        name: '教育解决方案',
        path: '/solution',
        index: 2,
        titleIndex: 2,
        pageTitle: ['解决方案', '教育解决方案']
      },
      {
        name: '医疗解决方案',
        path: '/solution',
        index: 3,
        titleIndex: 2,
        pageTitle: ['解决方案', '医疗解决方案']
      },
      {
        name: '军警部队解决方案',
        path: '/solution',
        index: 4,
        titleIndex: 2,
        pageTitle: ['解决方案', '军警部队解决方案']
      }
    ]
  },
  {
    title: '关于我们',
    value: [
      {
        name: '公司介绍',
        path: '/about_us_view',
        titleIndex: 5,
        pageTitle: ['关于我们', '公司介绍']
      },
      {
        name: '新闻动态',
        path: '/about_us',
        titleIndex: 5,
        pageTitle: ['关于我们', '新闻动态']
      },
      {
        name: '加入我们',
        path: '/about_detail',
        titleIndex: 5,
        pageTitle: ['关于我们', '加入我们']
      }
    ]
  },
  {
    title: '合作咨询',
    value: [
      {
        name: '何洋 13225532650',
        path: ''
      },
      {
        name: '市场/媒体',
        path: '',
      },
      {
        name: 'marketing@nfznxr.com',
        path: ''
      }
    ]
  },
]
const loadUrl: any = ref({})
const to1 = (url: string) => {
  window.scrollTo(0, 0)
  window.location.href = url
}

const changeRouter = (e: any, titleIndex: number) => {
  if (!e.path) return
  if (e.path == '/solution') {
    router.push(`${e.path}?type=${e.index}&pathName=${e.pageTitle[0]},${e.pageTitle[1]}`)
    store.state.programmeIndex = e.index
  } else {
    router.push(`${e.path}?pathName=${e.pageTitle[0]},${e.pageTitle[1]}`);
  }
  store.state.titleIndex = titleIndex;
  store.state.pageTitle = [...e.pageTitle]
}

onMounted(async () => {
  //
})
</script>
<style scoped lang="less">
.bottom-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #D71819;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 20rem;

  .bottom-content {
    width: calc(843rem + 17vw);
    height: 100%;

    .bottom-wrap {
      display: flex;
      justify-content: space-between;
      align-items: start;
      padding-bottom: 10rem;
      padding-right: 45rem;
      box-sizing: border-box;

      .bottom-logo {
        width: 199rem;
        height: 44rem;
        margin-top: 28rem;
        margin-bottom: 18rem;

        img {
          width: 100%;
          height: 100%;
        }
        // cursor: pointer;
      }

      .bottom-codes {
        width: 189rem;
        margin-left: 5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 10rem;
        color: #FFFFFF;
      }

      img {
        width: 80rem;
        height: 80rem;
        margin-bottom: 8rem;
      }

      .bottom-right {
        width: 557rem;
        font-family: PingFangSC-Regular;
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin-top: 51rem;
        text-align: left;

        div {
          margin-bottom: 10rem;
          font-weight: 400;
          font-size: 14rem;
          color: #FFFFFF;
        }

        .title {
          font-weight: 800;
          font-size: 18rem;
          color: #FFFFFF;
          margin-bottom: 20rem;
        }

        .blod {
          font-weight: 800;
          font-size: 18rem;
          color: #FFFFFF;
        }
      }
    }

    .line {
      width: 100%;
      height: 0.5px;
      background: rgba(203, 203, 203, 0.41);
      margin-bottom: 19rem;
    }

    .filings {
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        font-family: PingFangSC-Regular;
        margin: 0 15rem;
        font-weight: 400;
        font-size: 12rem;
        color: #FFFFFF;
      }

      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .url-list {
      cursor: pointer;
    }
  }
}
</style>