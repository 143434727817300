<template>
  <div class="medical-treatment">
    <div class="title-font-style-m" style="color: #000000;">医疗解决方案</div>
    <div class="illustrate">
      <div>利用元宇宙/VR技术实现医术培训、康复治疗、病理研究和预防知识普及、病情评估等，并能为不同地区提供高水平医疗服务，减少就医成本和时间，改善医疗资源分配和利用，对整个医疗行业产生积极变革。</div>
    </div>
    <div class="subtitle">行业痛点</div>
    <div class="fzqj-box">
      <div class="fzqj">
        <div v-for="(item, index) in prospects" :key="index">
          <img :src="item.url" />
          <div>
            <div class="title">
              <img :src="item.src" />
              <span>{{ item.title }}</span>
            </div>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="subtitle">方案优势</div>
    <div class="fays">
      <img :src="loadUrl.fays_bg" />
      <div>
        <div>
          <img class="l1" src="@/assets/images/jjfa/jiangben.png" />
          <div class="l2">降低成本</div>
        </div>
        <div class="l3">基于VR设备配合医学培训、康复治疗、医疗研究等，能高效打通信息共享的途径，降低成本。</div>
      </div>
      <div>
        <div>
          <img class="l1" :src="loadUrl.zengxiao" />
          <div class="l2" style="margin-top: 12rem;">提升效率</div>
        </div>
        <div class="l3">VR医疗方案可以让医学生近距离接触及学习不同类型的医疗模型，在医疗培训过程中以第一视角观察操作步骤，也能让康复患者跟随VR系统指令复健，显著提升效率。</div>
      </div>
    </div>
    <div class="yycj">
      <div class="subtitle">应用场景</div>
      <div class="yycj-content">
        <img src="@/assets/images/solution/medical-bg.png" />
      </div>
    </div>
    <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div @click="toOtherPath(`/case_detail?id=${casesList.id}&pathName=解决方案,医疗解决方案,应用案例`, ['解决方案', '医疗解决方案', '应用案例'])">
          <img :src="casesList.ossPath" />
          <div class="content">
            <div class="title">{{ casesList.title }}</div>
            <div class="detail">
              {{ casesList.introduction }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const store = useStore()
const router = useRouter()
const casesList: any = ref({})
const prospects: any = ref(
  [
    {
      title: '医疗资源分配不均',
      content: '由于医疗资源分配不均，欠发达地区比较难接触到最先进的医学研究成果和预防知识普及。',
    },
    {
      title: '传统康复成本高昂',
      content: '患者接受规范的康复治疗需要专业的设备辅助，对从业人员的专业水平要求较高，成本也较高。',
    },
    {
      title: '传统医疗培训效果欠佳',
      content: '参加医学培训需要承担高昂的线下时间成本和差旅费用，并且在培训中观察的视角也受到限制，影响培训效果。',
    }
  ]
)

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(async () => {
  selectCommonElement({ columnId: 4 }).then((res: any) => {
    casesList.value = [...res].filter(e => e.industry == 4)[0] || {}
  })

  const zengxiao: any = await asynchronousRetrieval('nfznxr-images/jjfa/zengxiao.png');
  const yycj_img8: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img8.png');
  const yycj_icon8: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-icon8.png');
  const yycj_icon9: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-icon9.png');
  const yycj_img9: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img9.png');
  const yycj_img10: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img10.png');
  const yycj_icon10: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-icon10.png');
  const yiliao_f1: any = await asynchronousRetrieval('nfznxr-images/jjfa/yiliao_f1.png');
  const yiliao_f1_icon: any = await asynchronousRetrieval('nfznxr-images/jjfa/yiliao_f1_icon.png');
  const yiliao_f2: any = await asynchronousRetrieval('nfznxr-images/jjfa/yiliao_f2.png');
  const yiliao_f2_icon: any = await asynchronousRetrieval('nfznxr-images/jjfa/yiliao_f2_icon.png');
  const yiliao_f3: any = await asynchronousRetrieval('nfznxr-images/jjfa/yiliao_f3.png');
  const yiliao_f3_icon: any = await asynchronousRetrieval('nfznxr-images/jjfa/yiliao_f3_icon.png');
  const fays_bg: any = await asynchronousRetrieval('nfznxr-images/jjfa/fays-bg.png');
  loadUrl.value = { zengxiao, yycj_img8, yycj_icon8, yycj_icon9, yycj_img9, yycj_img10, yycj_icon10, yiliao_f1, yiliao_f1_icon, yiliao_f2, yiliao_f2_icon, yiliao_f3, yiliao_f3_icon, fays_bg }

  prospects.value[0].url = yiliao_f1;
  prospects.value[0].src = yiliao_f1_icon;
  prospects.value[1].url = yiliao_f2;
  prospects.value[1].src = yiliao_f2_icon;
  prospects.value[2].url = yiliao_f3;
  prospects.value[2].src = yiliao_f3_icon;
})
</script>
<style scoped lang="less">
.medical-treatment {
  background-color: #F7F7F7;
  padding-top: 100rem;
  overflow: hidden;

  .illustrate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16rem 0 64rem;

    &>div {
      font-weight: 400;
      font-size: 24rem;
      color: #4A4A4A;
      width: 600rem;
      line-height: 36rem;
    }
  }

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 32rem;
    color: #000000;
    line-height: 44rem;
    display: inline-block;
    padding: 0 16rem;

    &::after {
      content: '';
      width: 100%;
      height: 20rem;
      background: rgba(215, 24, 25, 0.21);
      position: absolute;
      left: 0;
      bottom: -5rem;
    }
  }

  .fzqj-box {
    margin: 46rem 0 64rem;
    width: 750rem;
    overflow: hidden;
    overflow-x: auto;

    .fzqj {
      width: 1392rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 36rem;

      &>div {
        width: 448rem;
        font-size: 0;
        box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 8rem;
        overflow: hidden;

        &>img {
          width: 448rem;
          height: 302rem;
        }

        &>div {
          padding: 30rem 29rem;
          font-weight: 400;
          font-size: 20rem;
          color: #5C5C5C;
          line-height: 30rem;
          text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
          text-align: left;
          height: 254rem;
          box-sizing: border-box;
          background: #FFFFFF;

          .title {
            font-weight: 600;
            font-size: 28rem;
            color: #333333;
            line-height: 38px;
            text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
            font-family: PingFangSC-Medium;
            margin-bottom: 15rem;

            img {
              width: 47rem;
              height: 46rem;
              vertical-align: middle;
              margin-right: 5rem;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .fays {
    position: relative;
    width: 100%;
    height: 438rem;
    margin-top: 46rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &>img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    &>div {
      margin: 0 5rem;
      width: 356rem;
      height: 366rem;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 8rem;
      backdrop-filter: blur(10rem);
      padding: 40rem 25rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div:first-child {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 18rem;
      }

      .l1 {
        width: 80rem;
        height: auto;
      }

      .l2 {
        font-weight: 600;
        font-size: 24rem;
        color: #414141;
        line-height: 30rem;
        font-family: PingFangSC-Medium;
        margin-left: 8rem;
      }

      .l3 {
        font-weight: 400;
        font-size: 20rem;
        color: #414141;
        line-height: 30rem;
        text-align: left;
      }
    }
  }

  .yycj {
    padding-top: 64rem;

    .yycj-content {
      margin-top: 46rem;
      width: 750rem;
      display: flex;
      justify-content: center;
      align-items: self-start;

      img {
        width: 680rem;
        height: 382rem;
      }
    }
  }

  .yyal {
    padding-top: 64rem;
    overflow: hidden;

    .yyal-content {
      margin: 46rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 680rem;
        background: #FFFFFF;
        border-radius: 32rem;
        box-sizing: border-box;
        cursor: pointer;
        padding: 24rem 24rem 34rem;
        margin-bottom: 48rem;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(46, 118, 255, 0.2), 0px 0px 4px 0px rgba(46, 118, 255, 0.2);
        }

        img {
          width: 630rem;
          height: 354rem;
          border-radius: 20rem;
        }

        .content {
          width: 632rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 30rem;
            color: #414141;
            line-height: 48rem;
            margin-top: 10rem;
          }

          .detail {
            font-weight: 400;
            font-size: 20rem;
            color: #414141;
            line-height: 30rem;
            margin-top: 6rem;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }

    .pagination {
      margin: 50rem 0 57rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &>div {
        width: 24rem;
        height: 24rem;
        line-height: 24rem;
        border-radius: 2rem;
        font-weight: 400;
        font-size: 14rem;
        color: #999999;
        margin: 0 5rem;
        text-align: center;
        cursor: pointer;
      }

      &>div.active {
        background: #F7F7F7;
        color: #414141;
      }

      .prev {
        width: 16rem;
        height: 16rem;
        background-image: url(~@/assets/images/solution/left-c.png);
        background-size: 100% 100%;
      }

      .next {
        width: 16rem;
        height: 16rem;
        background-image: url(~@/assets/images/solution/left-c.png);
        background-size: 100% 100%;
        transform: rotate(180deg);
      }
    }
  }
}
</style>