<template>
  <div>
    <banner-c></banner-c>
    <div class="success-cases">
      <div class="title-font-style">成功案例</div>
      <div class="cases-list">
        <div v-for="(item, index) in casesList" :key="index" @click="toOtherPath(`/case_detail?id=${item.id}&pathName=成功案例,应用案例`, ['成功案例', '应用案例'])">
          <div>
            <img :src="item.ossPath" />
            <span v-if="item.type">{{ item.type }}</span>
          </div>
          <div>
            <div class="title">{{ item.title }}</div>
            <div class="subtitle">{{ item.subTitle }}</div>
            <div class="content">{{ item.introduction }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import BannerC from '@/components/BannerC.vue';


const store = useStore()
const casesList: any = ref([])
const router = useRouter()

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(() => {
  selectCommonElement({ columnId: 4 }).then((res: any) => {
    casesList.value = [...res]
  })
})
</script>
<style scoped lang="less">
.success-cases {
  margin-top: 40rem;
  display: inline-block;
  font-size: 0;
  margin-bottom: 80rem;

  .cases-list {
    margin-top: 60rem;
    width: 1080rem;
    font-size: 14rem;
    color: #333333;

    &>div {
      width: 100%;
      height: 264rem;
      padding: 20rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 20rem;
      border-radius: 8rem;
      cursor: pointer;

      &:hover {
        box-shadow: 0px 10px 12px 0px rgba(215,24,25,0.2), 0px 0px 4px 0px rgba(215,24,25,0.2);
      }

      &>div:first-child {
        position: relative;
        width: 400rem;
        height: 224rem;
        background-color: #efefef;

        img {
          width: 100%;
          height: 100%;
        }

        &>span {
          position: absolute;
          left: 0;
          top: 0;
          padding: 0 20rem;
          height: 40rem;
          line-height: 40rem;
          text-align: center;
          background: #D71819;
          box-shadow: 0 2rem 9rem 0 rgba(0, 0, 0, 0.28);
          border-radius: 0 0 100rem 0;
          font-size: 14rem;
          color: #FFFFFF;
        }
      }

      &>div:last-child {
        width: 607rem;
        text-align: left;

        .title {
          margin-top: 8rem;
          font-weight: 600;
          font-size: 24rem;
          color: #0A0A0A;
          font-family: PingFangSC-Medium;
          margin-bottom: 9rem;
        }

        .subtitle {
          font-family: PingFangSC-Medium;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          margin-bottom: 20rem;
        }

        .content {
          font-weight: 400;
          font-size: 14rem;
          color: #333333;
          line-height: 30rem;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>