<template>
  <div class="education">
    <div class="title-font-style-m" style="color: #000000;">军警部队解决方案</div>
    <div class="illustrate">
      <div>VR技术在军事领域可以模拟真实战斗环境，使受训士兵“真正”进入形象逼真的战场，从而增强临场反应能力，减少人员损伤，大大提高训练质量。</div>
    </div>
    <div class="subtitle">方案优势</div>
    <div class="fays">
      <img :src="loadUrl.fays_bg" />
      <div>
        <div>
          <img src="@/assets/images/jjfa/advantage1.png" />
          <div>直观全面地反映演习的动态和交互，环境逼真。</div>
        </div>
        <div>
          <img src="@/assets/images/jjfa/advantage2.png" />
          <div>降低了演习的时间、空间和地域的限制。</div>
        </div>
      </div>
      <div>
        <div>
          <img src="@/assets/images/jjfa/advantage3.png" />
          <div>使用方便，训练成本低。</div>
        </div>
        <div>
          <img src="@/assets/images/jjfa/advantage4.png" />
          <div>减少人员损伤，提升训练质量。</div>
        </div>
      </div>
    </div>
    <div class="yycj">
      <div class="subtitle">应用场景</div>
      <div class="yycj-content">
        <img src="@/assets/images/solution/military-police.png" />
      </div>
    </div>
    <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div
          @click="toOtherPath(`/case_detail?id=${casesList.id}&pathName=解决方案,军警部队解决方案,应用案例`, ['解决方案', '军警部队解决方案', '应用案例'])">
          <img :src="casesList.ossPath" />
          <div class="content">
            <div class="title">{{ casesList.title }}</div>
            <div class="detail">
              {{ casesList.introduction }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div
          @click="toOtherPath(`/case_detail?id=${casesList.id}&pathName=解决方案,军警部队解决方案,应用案例`, ['解决方案', '军警部队解决方案', '应用案例'])">
          <img :src="casesList.ossPath" />
          <div class="content">
            <div class="title">{{ casesList.title }}</div>
            <div>{{ casesList.subTitle }}</div>
            <div class="detail">
              {{ casesList.introduction }}
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const store = useStore()
const router = useRouter()
const casesList: any = ref({})

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(async () => {
  selectCommonElement({ columnId: 4 }).then((res: any) => {
    casesList.value = [...res].filter(e => e.industry == 5)[0] || {}
  })

  const yycj_img11: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img11.png');
  const yycj_img12: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img12.png');
  const yycj_img13: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img13.png');
  const yycj_img14: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img14.png');
  const fays_bg: any = await asynchronousRetrieval('nfznxr-images/jjfa/fays-bg.png');
  loadUrl.value = { yycj_img11, yycj_img12, yycj_img13, yycj_img14, fays_bg }
})
</script>
<style scoped lang="less">
.education {
  background-color: #F7F7F7;
  padding-top: 100rem;
  overflow: hidden;

  .illustrate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16rem 0 64rem;

    &>div {
      font-weight: 400;
      font-size: 24rem;
      color: #4A4A4A;
      width: 600rem;
      line-height: 36rem;
    }
  }

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 32rem;
    color: #000000;
    line-height: 44rem;
    display: inline-block;
    padding: 0 16rem;

    &::after {
      content: '';
      width: 100%;
      height: 20rem;
      background: rgba(215, 24, 25, 0.21);
      position: absolute;
      left: 0;
      bottom: -5rem;
    }
  }

  .hytd-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 80rem;

    .hytd {
      width: 1204rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &>div {
        width: 394rem;
        font-size: 0;

        &>div {
          padding: 30rem 29rem;
          font-weight: 400;
          font-size: 16rem;
          color: #5C5C5C;
          line-height: 30rem;
          text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
          text-align: left;
          height: 200rem;
          box-sizing: border-box;
          background: #FFFFFF;
          box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 0 0 8rem 8rem;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30px;
            text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
            font-family: PingFangSC-Medium;
            margin-bottom: 15rem;

            img {
              width: 47rem;
              height: 46rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }

      .images {
        width: 393rem;
        height: 330rem;
        background: #F2F4FA;
        box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 8rem 8rem 0 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0;

        img {
          width: 229rem;
          height: 250rem;
        }
      }
    }
  }

  .fays {
    position: relative;
    width: 750rem;
    height: 764rem;
    margin: 46rem 0 64rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;

    &>img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    &>div {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      &>div {
        width: 356rem;
        // height: 366rem;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 8rem;
        backdrop-filter: blur(10rem);
        margin: 4rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-weight: 400;
        font-size: 20rem;
        color: #333333;
        line-height: 30rem;
        text-align: center;

        img {
          width: 100%;
          height: auto;
        }

        &>div {
          padding: 30rem 35rem 40rem;
          line-height: 30rem;
        }
      }
    }
  }

  .yycj {
    .yycj-content {
      margin-top: 46rem;
      width: 750rem;
      display: flex;
      justify-content: center;
      align-items: self-start;

      img {
        width: 680rem;
        height: 382rem;
      }
    }
  }

  .yyal {
    padding-top: 64rem;
    overflow: hidden;

    .yyal-content {
      margin: 46rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 680rem;
        background: #FFFFFF;
        border-radius: 32rem;
        box-sizing: border-box;
        cursor: pointer;
        padding: 24rem 24rem 34rem;
        margin-bottom: 48rem;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(46, 118, 255, 0.2), 0px 0px 4px 0px rgba(46, 118, 255, 0.2);
        }

        img {
          width: 630rem;
          height: 354rem;
          border-radius: 20rem;
        }

        .content {
          width: 632rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 30rem;
            color: #414141;
            line-height: 48rem;
            margin-top: 10rem;
          }

          .detail {
            font-weight: 400;
            font-size: 20rem;
            color: #414141;
            line-height: 30rem;
            margin-top: 6rem;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }

    .pagination {
      margin: 50rem 0 57rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &>div {
        width: 24rem;
        height: 24rem;
        line-height: 24rem;
        border-radius: 2rem;
        font-weight: 400;
        font-size: 14rem;
        color: #999999;
        margin: 0 5rem;
        text-align: center;
        cursor: pointer;
      }

      &>div.active {
        background: #F7F7F7;
        color: #414141;
      }

      .prev {
        width: 16rem;
        height: 16rem;
        background-image: url(~@/assets/images/solution/left-c.png);
        background-size: 100% 100%;
      }

      .next {
        width: 16rem;
        height: 16rem;
        background-image: url(~@/assets/images/solution/left-c.png);
        background-size: 100% 100%;
        transform: rotate(180deg);
      }
    }
  }
}
</style>