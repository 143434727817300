<template>
  <img src="@/assets/images/icon/view-more.png" />
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

onMounted(async() => {
  //
})
</script>
<style scoped lang="less">
img {
  width: 30rem;
  height: 30rem;
}
</style>