<template>
  <div>
    <banner-c></banner-c>
    <div class="xxqd">
      <div class="title-font-style-m">线下渠道</div>
      <div class="text">
        <div class="p1">
          <span>年平均增长</span>
          <span class="keynote">59%</span>
          <span> 遍布数百城市 </span>
          <span class="keynote">4000+</span>
          <span> 渠道</span>
        </div>
        <div class="p2">
          <span>覆盖 </span>
          <span class="keynote">华东、华南、华北、西南 </span>
          <span>等消费活跃城市，同时全国渠道网络正在加速建立</span>
        </div>
        <div class="p2">
          <span>2016年以来销售额平均年增长率</span>
          <span class="keynote"> 59%</span>
          <span>，凝丰智能渠道销售额占行业整体销售额</span>
          <span class="keynote"> 60%+</span>
        </div>
      </div>
      <div class="channel-img">
        <img :src="loadUrl.channel1" />
      </div>
    </div>
    <div class="xsqd">
      <div class="title-font-style-m">线上渠道</div>
      <div class="text">
        <div class="p1">
          <span>覆盖 </span>
          <span class="keynote" style="font-size: 40rem;">10+</span>
          <span> 平台 引流 </span>
          <span class="keynote" style="font-size: 40rem;">百万</span>
          <span> 流量</span>
        </div>
        <div class="p2">
          <span>构建完善的线上流量平台矩阵，与线下渠道形成互补优势，在线销售量常年位居平台销量前列 </span>
        </div>
      </div>
      <div class="channel-img">
        <img :src="loadUrl.channel2" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import BannerC from '@/components/BannerC.vue';
import { asynchronousRetrieval } from '@/utils'

onMounted(async () => {
  const channel1: any = await asynchronousRetrieval('nfznxr-images/channel1.png');
  const channel2: any = await asynchronousRetrieval('nfznxr-images/channel2.png');
  loadUrl.value = { channel1, channel2 }
})
const loadUrl: any = ref({})
</script>
<style scoped lang="less">
.xxqd,
.xsqd {
  background-color: #F7F7F7;
  font-size: 0;
  padding-top: 40rem;

  .text {
    margin-top: 34rem;
    font-family: SourceHanSansCN, SourceHanSansCN;

    .p1 {
      font-weight: 400;
      font-size: 28rem;
      color: #5D5D5D;
      margin-bottom: 28rem;

      &>span {
        vertical-align: middle;
      }

      .keynote {
        position: relative;
        top: -7rem;
        font-size: 36rem;
        color: #D71819;
        font-weight: 800;
        font-family: PingFangSC-Medium;
        padding: 0 6rem;

        &::after {
          content: '';
          height: 16rem;
          width: 100%;
          background: rgba(215, 24, 25, 0.21);
          position: absolute;
          left: 0;
          bottom: 2rem;
        }
      }
    }

    .p2 {
      font-weight: 400;
      font-size: 28rem;
      color: #5D5D5D;
      padding: 0 30rem 0 48rem;
      box-sizing: border-box;
      text-align: left;
      line-height: 40rem;

      .keynote {
        font-weight: bold;
        color: #D71819;
      }
    }
  }

  .channel-img {
    width: 750rem;
    height: 289rem;
    display: inline-block;
    margin-top: 24rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.xsqd {
  background-color: #fff;
  padding-top: 0;
  margin-top: 64rem;
  overflow: hidden;
}
</style>