
export const pathTable: any = {
  home: 0,
  product_service: 1,
  channel_expansion: 1,
  product_detail: 1,
  solution: 2,
  success_cases: 3,
  case_detail: 3,
  collaboratives: 4,
  about_us_view: 5,
  news_detail: 5,
  about_us: 6,
  about_detail: 7
}

export const pageRoute: any = {
  '数智产品': '/product_service?pathName=产品服务,数智产品',
  '文娱解决方案': '/solution?type=0&pathName=解决方案,文娱解决方案',
  '工业解决方案': '/solution?type=1&pathName=解决方案,工业解决方案',
  '教育解决方案': '/solution?type=2&pathName=解决方案,教育解决方案',
  '医疗解决方案': '/solution?type=3&pathName=解决方案,医疗解决方案',
  '军警部队解决方案': '/solution?type=4&pathName=解决方案,军警部队解决方案',
  '解决方案': '/solution?type=0&pathName=解决方案,文娱解决方案',
  '成功案例': '/success_cases',
  '新闻动态': '/about_us?pathName=关于我们,新闻动态',
  '加入我们': '/about_detail?pathName=关于我们,加入我们',
  '关于我们': '/about_us_view?pathName=关于我们,公司介绍',
  '应用案例': '/success_cases'
}