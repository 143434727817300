import { createStore } from 'vuex'

export default createStore({
  state: {
    programmeIndex: 0,
    titleIndex: 0,
    pageTitle: [],
    isBlankBg: '',
    userAgent: 'pc'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
