import axios from "axios";
import { ElMessage } from "element-plus";

// 控制多个请求报错时错误提示框始终只显示一个
export const uniqueMessageBox = (function () {
  const arr: any = [];
  return function (msg: any) {
    if (!arr.length) {
      arr.push(msg);
      ElMessage({
        showClose: true,
        message: msg,
        type: "error",
        duration: 2000,
        onClose: () => {
          arr.shift();
        },
      });
    }
  };
})();

// 线上环境，baseURL加上/api
const baseURL = process.env.NODE_ENV === 'production' ? '/website' : '/website1'
const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
});

// 请求拦截器
axiosInstance.interceptors.request.use(
  function (config) {
    // if(process.env.NODE_ENV === 'production') {
    //   config.url = config.url?.replace('http://', 'https://');
    // }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    uniqueMessageBox("网络错误");
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
  function (error) {
    uniqueMessageBox("网络错误");
    return Promise.reject(error);
  }
);

export default axiosInstance;
