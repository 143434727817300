<template>
  <div>
    <banner-c></banner-c>
    <div class="news">
      <div class="news-content">
        <div class="title-font-style-m">新闻动态</div>
        <div class="yyal-content">
          <div v-for="(item, index) in newsList" :key="index"
            @click="toOtherPath(`/news_detail?id=${item.id}&pathName=关于我们,新闻动态,新闻详情`, ['关于我们', '新闻动态', '新闻详情'])">
            <img :src="item.ossPath" />
            <div class="content">
              <div class="title">{{ item.title }}</div>
              <div class="detail">
                {{ item.introduction }}
              </div>
              <div>{{ item.createTime }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import BannerC from '@/components/BannerC.vue';

const store = useStore()
const newsList: any = ref([])
const router = useRouter()
const oldNewsList: any = ref([])
const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(() => {
  selectCommonElement({ columnId: 5 }).then((res: any) => {
    oldNewsList.value = [...res]
    newsList.value = res.slice(0, 6)
  })
})
</script>
<style scoped lang="less">
.news {
  padding-top: 40rem;
  display: flex;
  justify-content: space-around;
  align-items: start;
  font-family: PingFangSC-Regular;
  background-color: #F7F7F7;
  min-height: calc(100vh - 889rem);

  .news-content {
    position: relative;
    width: 750rem;

    .yyal-content {
      margin: 24rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 750rem;
        background: #FFFFFF;
        box-sizing: border-box;
        cursor: pointer;
        padding: 24rem 24rem 34rem;
        margin-bottom: 48rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(46, 118, 255, 0.2), 0px 0px 4px 0px rgba(46, 118, 255, 0.2);
        }

        img {
          width: 630rem;
          height: 354rem;
          border-radius: 20rem;
        }

        .content {
          width: 632rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 32rem;
            color: #414141;
            line-height: 48rem;
            margin-top: 16rem;
          }

          .detail {
            font-weight: 400;
            font-size: 20rem;
            color: #414141;
            line-height: 30rem;
            margin-top: 6rem;
            font-family: PingFangSC-Regular;
          }

          &>div:last-child {
            font-weight: 400;
            font-size: 20rem;
            color: #B2B2B2;
            line-height: 30rem;
            margin-top: 8rem;
          }
        }
      }
    }
  }
}
</style>