<template>
  <div class="bottom-box">
    <div>
      <div v-for="(item, index) in routerList" :key="index" class="navigation-list"
        :class="showListIndex == index ? 'active' : ''">
        <div class="title" @click="showListIndex == index ? showListIndex = -1 : showListIndex = index">
          <span>{{ item.title }}</span>
          <img src="@/assets/images/home/prev-icon.png" />
        </div>
        <div v-show="showListIndex == index" v-for="(e, i) in item.value" :key="i" @click="changeRouter(e, e.titleIndex)">{{
          e.name }}</div>
      </div>
    </div>
    <div class="bottom-box-text">
      <div>
        <div>上海市徐汇区钦州北路1001号光启园7号楼6层</div>
        <div>沪ICP备2023012463号—1</div>
        <div>版权所有 ©️ 2023上海凝聚元界信息科技有限公司</div>
        <div><img src="@/assets/images/beian-icon.png" /><span>沪公网安备 31010402010108号</span></div>
      </div>
      <div>
        <div>
          <img src="@/assets/images/code1.png" />
          <div>公众号</div>
        </div>
        <div>
          <img src="@/assets/images/code2.png" />
          <div>视频号</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'

const router = useRouter()
const showListIndex = ref(-1)
const store = useStore()
const routerList: any = [
  {
    title: '产品服务',
    value: [
      {
        name: '数智产品',
        path: '/product_service',
        titleIndex: 1,
        pageTitle: ['产品服务', '数智产品']
      },
      {
        name: '渠道拓展',
        path: '/channel_expansion',
        titleIndex: 1,
        pageTitle: ['产品服务', '渠道拓展']
      }
    ]
  },
  {
    title: '解决方案',
    value: [
      {
        name: '文娱解决方案',
        path: '/solution',
        index: 0,
        titleIndex: 2,
        pageTitle: ['解决方案', '文娱解决方案']
      },
      {
        name: '工业解决方案',
        path: '/solution',
        index: 1,
        titleIndex: 2,
        pageTitle: ['解决方案', '工业解决方案']
      },
      {
        name: '教育解决方案',
        path: '/solution',
        index: 2,
        titleIndex: 2,
        pageTitle: ['解决方案', '教育解决方案']
      },
      {
        name: '医疗解决方案',
        path: '/solution',
        index: 3,
        titleIndex: 2,
        pageTitle: ['解决方案', '医疗解决方案']
      },
      {
        name: '军警部队解决方案',
        path: '/solution',
        index: 4,
        titleIndex: 2,
        pageTitle: ['解决方案', '军警部队解决方案']
      }
    ]
  },
  {
    title: '关于我们',
    value: [
      {
        name: '公司介绍',
        path: '/about_us_view',
        titleIndex: 5,
        pageTitle: ['关于我们', '公司介绍']
      },
      {
        name: '新闻动态',
        path: '/about_us',
        titleIndex: 5,
        pageTitle: ['关于我们', '新闻动态']
      },
      {
        name: '加入我们',
        path: '/about_detail',
        titleIndex: 5,
        pageTitle: ['关于我们', '加入我们']
      }
    ]
  },
  {
    title: '合作咨询',
    value: [
      {
        name: '何洋 13225532650',
        path: '',
      },
    ]
  },
]

const changeRouter = (e: any, titleIndex: number) => {
  if (!e.path) return
  if (e.path == '/solution') {
    router.push(`${e.path}?type=${e.index}&pathName=${e.pageTitle[0]},${e.pageTitle[1]}`)
    store.state.programmeIndex = e.index
  } else {
    router.push(`${e.path}?pathName=${e.pageTitle[0]},${e.pageTitle[1]}`);
  }
  store.state.titleIndex = titleIndex;
  store.state.pageTitle = [...e.pageTitle]
}
</script>
<style scoped lang="less">
.bottom-box {
  color: #fff;
  padding: 28rem 36rem 24rem;
  box-sizing: border-box;
  background-color: #D81418;

  .navigation-list {
    border-bottom: 0.39rem solid rgba(255, 255, 255, 0.5);

    &>div {
      text-align: left;
      font-size: 20rem;
      line-height: 28rem;
      margin-bottom: 16rem;
    }

    .title {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 54rem;
      line-height: 54rem;
      font-size: 24rem;
      margin-bottom: 0;

      img {
        width: 16rem;
        height: 16rem;
      }
    }

    &.active {
      padding-bottom: 8rem;

      .title {
        margin-bottom: 6rem;
      }

      img {
        transform: rotate(-90deg);
      }
    }
  }

  .bottom-box-text {
    margin-top: 24rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-weight: 500;
    font-size: 16rem;
    color: #fff;

    &>div:first-child>div {
      margin-bottom: 8rem;

      img {
        width: 20rem;
        height: auto;
        vertical-align: middle;
      }

      span {
        vertical-align: middle;
        margin-left: 4rem;
      }
    }

    &>div:last-child {
      margin-right: -6rem;
      width: 236rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // font-size: 0;

      &>div {
        width: 108rem;
        height: 123rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      img {
        width: 108rem;
        height: 108rem;
      }
    }
  }
}
</style>