<template>
  <div>
    <banner-c></banner-c>
    <div class="news">
      <div class="news-content">
        <div class="title">新闻动态</div>
        <div class="news-content-list">
          <div v-for="(item, index) in newsList" :key="index" @click="toOtherPath(`/news_detail?id=${item.id}&pathName=关于我们,新闻动态,新闻详情`, ['关于我们', '新闻动态', '新闻详情'])">
            <div class="news-image">
              <img :src="item.ossPath" />
            </div>
            <div class="news-text">
              <div class="title">{{ item.title }}</div>
              <div class="date">{{ item.subTitle }}</div>
              <div class="content">{{ item.introduction }}</div>
            </div>
          </div>
        </div>
        <div class="view-more" v-if="oldNewsList.length > newsList.length" @click="showMore">
          <div>查看更多</div>
          <img src="/images/bottom-red.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import BannerC from '@/components/BannerC.vue';

const store = useStore()
const newsList: any = ref([])
const router = useRouter()
const oldNewsList: any = ref([])

const showMore = () => {
  newsList.value = oldNewsList.value.slice(0, Math.min(newsList.value.length + 6, oldNewsList.value.length))
}

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(() => {
  selectCommonElement({ columnId: 5 }).then((res: any) => {
    oldNewsList.value = [...res]
    newsList.value = res.slice(0, 6)
  })
})
</script>
<style scoped lang="less">
.news {
  margin-top: 25rem;
  display: flex;
  justify-content: space-around;
  align-items: start;
  font-family: PingFangSC-Regular;

  .news-content {
    position: relative;
    width: 1200rem;
    margin-bottom: 40rem;
    padding-bottom: 142rem;

    &>.title {
      font-weight: 600;
      font-size: 60rem;
      color: #333333;
      margin-bottom: 65rem;
    }

    .news-content-list {
      width: 100%;

      &>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20rem;
        cursor: pointer;
        padding: 20rem;
        box-sizing: border-box;
        text-align: justify;
      }

      &>div:hover {
        box-shadow: 0px 10px 12px 0px rgba(215,24,25,0.2), 0px 0px 4px 0px rgba(215,24,25,0.2);
        border-radius: 8rem;
      }

      .news-image {
        width: 541rem;
        height: 334rem;
        border-radius: 8rem;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .news-text {
        width: 579rem;
        height: 334rem;
        text-align: justify;

        &>.title {
          font-weight: bold;
          font-size: 24rem;
          color: #D71819;
          margin-top: 20rem;
          margin-bottom: 10rem;
        }

        .date {
          margin-bottom: 40rem;
          font-weight: 400;
          font-size: 20rem;
          color: #8E8E8E;
        }

        .content {
          font-weight: 400;
          font-size: 18rem;
          color: #333333;
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .view-more {
      width: 146rem;
      height: 42rem;
      background: rgba(216, 20, 22, 0.05);
      border-radius: 60rem;
      position: absolute;
      left: 50%;
      bottom: 60rem;
      margin-left: -73rem;
      font-weight: 400;
      font-size: 16rem;
      color: #D81416;
      background-color: rgba(216, 20, 22, 0.05);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 25rem;
      box-sizing: border-box;
      cursor: pointer;

      img {
        width: 14rem;
        height: 14rem;
      }
    }
  }
}
</style>