<template>
  <div class="banner">
    <img :src="bannerUrl" />
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { asynchronousRetrieval } from '@/utils'

const bannerUrl = ref('')

onMounted(async() => {
  const d: any = await asynchronousRetrieval('nfznxr-images/banner.png');
  bannerUrl.value = d;
})
</script>
<style scoped lang="less">
.banner {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>