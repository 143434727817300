<template>
  <div class="open-cooperation-child">
    <div class="shuzhi-form" @mouseenter="showForm(1)">
      <img :src="loadUrl.shuzhi" />
      <div class="text">我是数智产品品牌方</div>
      <div class="form-style" v-if="showIndex == 1">
        <div class="title">品牌方合作咨询</div>
        <div class="form-content">
          <el-form ref="ruleFormRef1" :model="ruleForm" :rules="rules" require-asterisk-position="right">
            <el-form-item label="姓名" prop="name">
              <el-input class="form-input" v-model="ruleForm.name" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="公司名称" prop="companyName">
              <el-input class="form-input" v-model="ruleForm.companyName" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="电话" prop="phone">
              <el-input class="form-input" v-model="ruleForm.phone" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="留言" prop="message">
              <el-input class="form-input" type="textarea" v-model="ruleForm.message" placeholder="请输入" resize="none" />
            </el-form-item>
            <el-form-item class="form-submit">
              <div class="btn-primary" @click="submitForm(ruleFormRef1, 1)">
                提交
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="close-icon" @click="showIndex = -1"></div>
      </div>
    </div>
    <div class="shuzhi-form" @mouseenter="showForm(2)">
      <img :src="loadUrl.zhongduan" />
      <div class="text">我是渠道端/零售终端</div>
      <div class="form-style" v-if="showIndex == 2">
        <div class="title">渠道端/零售终端合作咨询</div>
        <div class="form-content">
          <el-form ref="ruleFormRef2" :model="ruleForm" :rules="rules" require-asterisk-position="right">
            <el-form-item label="姓名" prop="name">
              <el-input class="form-input" v-model="ruleForm.name" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="公司名称" prop="companyName">
              <el-input class="form-input" v-model="ruleForm.companyName" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="电话" prop="phone">
              <el-input class="form-input" v-model="ruleForm.phone" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="留言" prop="message">
              <el-input class="form-input" type="textarea" v-model="ruleForm.message" placeholder="请输入" resize="none" />
            </el-form-item>
            <el-form-item class="form-submit">
              <div class="btn-primary" @click="submitForm(ruleFormRef2, 2)">
                提交
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="close-icon" @click="showIndex = -1"></div>
      </div>
    </div>
    <div class="shuzhi-form" @mouseenter="showForm(3)">
      <img :src="loadUrl.Bduan" />
      <div class="text">我是B/G端需求方</div>
      <div class="form-style" v-if="showIndex == 3">
        <div class="title">B/G端需求方合作咨询</div>
        <div class="form-content">
          <el-form ref="ruleFormRef3" :model="ruleForm" :rules="rules" require-asterisk-position="right">
            <el-form-item label="姓名" prop="name">
              <el-input class="form-input" v-model="ruleForm.name" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="公司名称" prop="companyName">
              <el-input class="form-input" v-model="ruleForm.companyName" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="电话" prop="phone">
              <el-input class="form-input" v-model="ruleForm.phone" placeholder="请输入" />
            </el-form-item>
            <el-form-item label="留言" prop="message">
              <el-input class="form-input" type="textarea" v-model="ruleForm.message" placeholder="请输入" resize="none" />
            </el-form-item>
            <el-form-item class="form-submit">
              <div class="btn-primary" @click="submitForm(ruleFormRef3, 3)">
                提交
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="close-icon" @click="showIndex = -1"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, watch, onMounted } from 'vue'
import type { FormInstance, FormRules, UploadUserFile } from 'element-plus'
import { addConsult } from '@/api'
import { ElMessage } from "element-plus";
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const showIndex = ref(-1)
let timer: any = null;
let upDataTime = ref(0)

interface RuleForm {
  name: string
  companyName: string
  phone: string
  message: string
}
const ruleFormRef1 = ref<FormInstance>()
const ruleFormRef2 = ref<FormInstance>()
const ruleFormRef3 = ref<FormInstance>()

const ruleForm: any = reactive<RuleForm>({
  name: '',
  companyName: '',
  phone: '',
  message: '',
})

const rules = reactive<FormRules<RuleForm>>({
  name: [
    { required: true, message: '请输入姓名', trigger: 'blur' }
  ],
  companyName: [
    { required: true, message: '请输入公司名', trigger: 'blur', }
  ],
  phone: [{ required: true, message: '请输入电话号码', trigger: 'blur', }],
})

const showForm = (index: number) => {
  const time = new Date().getTime()
  if (time - upDataTime.value < 10) return
  clearTimeout(timer)
  showIndex.value = index
}

const submitForm = async (formEl: FormInstance | undefined, type: number) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      addConsult({ ...ruleForm, consultType: type, appId: 1 }).then((res) => {
        ElMessage({
          message: '感谢您的反馈!',
          type: "success",
          duration: 2000,
        })
        ruleForm.name = ''
        ruleForm.companyName = ''
        ruleForm.phone = ''
        ruleForm.message = ''
      })
    } else {
      console.log('error submit!', fields)
    }
  })
}

onMounted(async() => {
  const shuzhi: any = await asynchronousRetrieval('nfznxr-images/shuzhi.png');
  const zhongduan: any = await asynchronousRetrieval('nfznxr-images/zhongduan.png');
  const Bduan: any = await asynchronousRetrieval('nfznxr-images/Bduan.png');
  loadUrl.value = { shuzhi, zhongduan, Bduan }
})

watch(showIndex, (newState) => {
  if (newState == -1) {
    upDataTime.value = new Date().getTime()
  }
})
</script>
<style scoped lang="less">
.open-cooperation-child {
  position: relative;
  width: 1199rem;
  height: 614rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &>div {
    width: 393rem;
    height: 614rem;
    position: relative;
    font-weight: 600;
    font-size: 26rem;
    color: #333333;
    font-family: PingFangSC-Medium;

    img {
      width: 100%;
      height: 100%;
    }

    &>div:not(.form-style) {
      position: relative;
      position: absolute;
      left: 30rem;
      bottom: 40rem;
      height: 58rem;
    }

    .text::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 51rem;
      height: 10rem;
      background-color: #D81417;
    }
  }

  .shuzhi-form {
    position: relative;

    .form-style {
      position: relative;
      position: absolute;
      left: 0;
      top: 0;
      width: 393rem;
      height: 614rem;
      background-color: rgba(216, 20, 23, 0.5);
      border-radius: 8rem;
      backdrop-filter: blur(4px);
      padding: 20rem 30rem 26rem 30rem;
      box-sizing: border-box;

      .form-submit {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }

    .title {
      font-weight: 500;
      font-size: 22rem;
      color: #FFFFFF;
      padding-bottom: 16rem;
    }

    .close-icon {
      position: absolute;
      right: 20rem;
      top: 20rem;
      width: 26rem;
      height: 26rem;
      background: url(/public/images/close-red.png);
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}
</style>