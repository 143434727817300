<template>
  <div class="news-detail">
    <div></div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, onUnmounted } from 'vue'
import { getCommonElementById, getStaticHtml, getOssUrls } from '@/api'
import { useStore } from 'vuex'
import { findSequences, findSequences2 } from '@/utils'

const store = useStore()
onMounted(() => {
  if (document.documentElement && document.documentElement.scrollTop) {
    document.documentElement.scrollTop = 0;
  } else if (document.body) {
    document.body.scrollTop = 0
  }
  const path: any = JSON.parse(window.sessionStorage.getItem('path') || '{}');
  if (path.query?.htmlId) {
    getStaticHtml({ id: path.query?.htmlId }).then((html: any) => {
      const resultKeys = findSequences(html);
      const resultSources = findSequences2(html);
      getOssUrls(resultKeys).then((res: any) => {
        resultKeys.forEach((urlKey, i) => {
          html = html.replace(resultSources[i], `source src="${res[i]}`)
        });
        (document.querySelector('.news-detail div') as any).innerHTML = html
      })
    })
    return
  }
  getCommonElementById({ id: path.query?.id }).then((res: any) => {
    if (res.relatedHtmlId) {
      getStaticHtml({ id: res.relatedHtmlId }).then((html: any) => {
        const resultKeys = findSequences(html);
        const resultSources = findSequences2(html);
        getOssUrls(resultKeys).then((res: any) => {
          resultKeys.forEach((urlKey, i) => {
            html = html.replace(resultSources[i], `source src="${res[i]}`)
          });
          (document.querySelector('.news-detail div') as any).innerHTML = html
        })
      })
    }
  })
})
</script>
<style scoped lang="less">
.news-detail {
  width: 100%;
  font-size: 0;
  min-height: calc(100vh - 469rem);

  &>div {
    width: 680rem;
    display: inline-block;
    font-size: 14rem;
    padding: 100rem 0;
  }
}
</style>