import request from "./request";

// 测试接口
export function login(data: { [k: string]: string }) {
  return request({
    url: "/authority/login",
    method: "post",
    data,
  });
}

// 获取产品种类
export function getProductTypes() {
  return request({
    url: "/getProductTypes",
    method: "get",
  });
}

// 获取产品分类
export function getProductProfile() {
  return request({
    url: "/getProductProfile",
    method: "get",
  });
}

// 提交意见表单
export function addConsult(data: any) {
  return request({
    url: "/addConsult",
    method: "post",
    data,
  });
}

// 获取产品列表，带分页
export function getProducts(data: any) {
  return request({
    url: `/getProducts?productTypeId=${data.productTypeId || ''}&brandId=${data.brandId || ''}&status=1&pageSize=999&pageNo=1`,
    method: "get",
  });
}

// 获取产品列表，不带分页
export function getProductList(data: any) {
  return request({
    url: `/getProductList?productTypeId=${data.productTypeId || ''}&brandId=${data.brandId || ''}&status=1`,
    method: "get",
  });
}

// 获取模块
export function selectCommonElement(data: any) {
  return request({
    url: `/selectCommonElement?appId=1&columnId=${data.columnId}&status=1`,
    method: "get",
  });
}

// 通过id获取产品详情
export function getProductById(data: any) {
  return request({
    url: `/getProductById?id=${data.id}`,
    method: "get",
  });
}

// 通过id获取新闻详情
export function getCommonElementById(data: any) {
  return request({
    url: `/getCommonElementById?id=${data.id}`,
    method: "get",
  });
}

// 通过id获取新闻详情
export function getStaticHtml(data: any) {
  return request({
    url: `/getStaticHtml?id=${data.id}`,
    method: "get",
  });
}

// 获取url数组
export function getOssUrls(data: any) {
  return request({
    url: '/getOssUrls',
    method: "post",
    data,
  });
}

// 获取url加密
export function getOssUrl(data: any) {
  return request({
    url: '/getOssUrl?key=' + data.key,
    method: "get",
    data,
  });
}