<template>
  <div class="news-detail">
    <div></div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted } from 'vue'
import { getCommonElementById, getStaticHtml, getOssUrls } from '@/api'
import { findSequences, findSequences2 } from '@/utils'

onMounted(() => {
  const path: any = JSON.parse(window.sessionStorage.getItem('path') || '{}');
  getCommonElementById({ id: path.query?.id }).then((res: any) => {
    if (res.relatedHtmlId) {
      getStaticHtml({ id: res.relatedHtmlId }).then((html: any) => {
        const resultKeys = findSequences(html);
        const resultSources = findSequences2(html);
        getOssUrls(resultKeys).then((res: any) => {
          resultKeys.forEach((urlKey, i) => {
            html = html.replace(resultSources[i], `source src="${res[i]}`)
          });
          (document.querySelector('.news-detail div') as any).innerHTML = html
        })
      })
    }

  })
  if (document.documentElement && document.documentElement.scrollTop) {
    document.documentElement.scrollTop = 0;
  } else if (document.body) {
    document.body.scrollTop = 0
  }
})
</script>
<style scoped lang="less">
.news-detail {
  width: 100%;
  font-size: 0;

  &>div {
    width: calc(843rem + 17vw);
    display: inline-block;
    font-size: 14rem;
    padding: 100rem 0;
  }
}
</style>