<template>
  <div class="solution-wrap">
    <banner-c></banner-c>
    <div class="solution-content">
      <div>
        <div class="solution-tab-wrap">
          <div class="solution-tab">
            <div v-for="(item, index) in solutionList" :key="index"
              :class="activeSolutionIndex == index ? 'active' : ''" @click="handleClickEvent(index, item)">{{ item }}
            </div>
          </div>
        </div>
        <entertainment-plan v-if="activeSolutionIndex == 0"></entertainment-plan>
        <industry-model v-if="activeSolutionIndex == 1"></industry-model>
        <education-model v-if="activeSolutionIndex == 2"></education-model>
        <medical-treatment v-if="activeSolutionIndex == 3"></medical-treatment>
        <military-police v-if="activeSolutionIndex == 4"></military-police>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted, watch } from 'vue'
import EntertainmentPlan from '@/components/mobile/EntertainmentPlan.vue';
import IndustryModel from '@/components/mobile/IndustryModel.vue';
import EducationModel from '@/components/mobile/EducationModel.vue';
import MedicalTreatment from '@/components/mobile/MedicalTreatment.vue';
import MilitaryPolice from '@/components/mobile/MilitaryPolice.vue';
import BannerC from '@/components/BannerC.vue';
import router from '@/router';
import store from '@/store';
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const solutionList = ['文娱解决方案', '工业解决方案', '教育解决方案', '医疗解决方案', '军警部队解决方案']
const activeSolutionIndex = ref(-1)

const handleClickEvent = (index: number, item: string) => {
  store.state.programmeIndex = index
  router.push(`/solution?type=${index}&pathName=解决方案,${item}`)
}

onMounted(async () => {
  const query: any = router.currentRoute.value.query;
  activeSolutionIndex.value = query?.type || 0

  const jjfa_bg: any = await asynchronousRetrieval('webimg/images/solution/jjfa-bg.png');
  loadUrl.value = { jjfa_bg }
})

watch(store.state, (newState) => {
  if (newState.programmeIndex != activeSolutionIndex.value) {
    activeSolutionIndex.value = newState.programmeIndex
  }
})
</script>
<style scoped lang="less">
.solution-wrap {
  background: #F7F7F7;
  min-height: calc(100vh - 400rem);

  .solution-banner {
    position: relative;
    height: 208rem;
    text-align: center;
    padding-top: 50rem;
    box-sizing: border-box;
    font-size: 24rem;
    color: #FFFFFF;

    &>img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &>div {
      position: relative;
      z-index: 1;
    }

    &>div:first-child {
      position: relative;
      font-weight: 600;
      font-size: 48rem;
      color: #FFFFFF;
      line-height: 72rem;

      span {
        margin: 0 46rem;
      }
    }
  }

  .solution-content {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .solution-tab-wrap {
      width: 750rem;
      overflow: hidden;
      overflow-x: auto;

      .solution-tab {
        font-weight: bold;
        font-size: 24rem;
        color: #333333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        width: 1380rem;
        padding: 20rem 30rem 0;

        &>div {
          padding: 0 42rem 16rem;
          cursor: pointer;
          border-bottom: 2rem solid transparent;
          color: #475565;
        }

        .active {
          color: #D71819;
          border-color: #D81418;
        }
      }
    }
  }
}
</style>