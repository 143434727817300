<template>
  <div>
    <banner-c></banner-c>
    <div class="success-cases">
      <div class="title-font-style-m">成功案例</div>
      <div class="yyal-content">
        <div @click="toOtherPath(`/case_detail?id=${item.id}&pathName=成功案例,应用案例`, ['成功案例', '应用案例'])"
          v-for="(item, index) in casesList" :key="index">
          <img :src="item.ossPath" />
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div class="detail">
              {{ item.introduction }}
            </div>
            <div>{{ item.createTime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import BannerC from '@/components/BannerC.vue';


const store = useStore()
const casesList: any = ref([])
const router = useRouter()

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(() => {
  selectCommonElement({ columnId: 4 }).then((res: any) => {
    casesList.value = [...res].map((e: any) => {
      const date = new Date(e.createTime)
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      const h = date.getHours();
      const mx = date.getMinutes();
      const s = date.getSeconds();
      e.createTime = `${y}-${m}-${d} ${h}:${mx}:${s}`
      return e;
    })
  })
})
</script>
<style scoped lang="less">
.success-cases {
  padding-top: 40rem;
  display: inline-block;
  font-size: 0;
  min-height: calc(100vh - 889rem);
  background-color: #F7F7F7;
  width: 100%;

  .yyal-content {
    margin: 24rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &>div {
      width: 750rem;
      background: #FFFFFF;
      box-sizing: border-box;
      cursor: pointer;
      padding: 24rem 24rem 34rem;
      margin-bottom: 48rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &:hover {
        box-shadow: 0px 10px 12px 0px rgba(46, 118, 255, 0.2), 0px 0px 4px 0px rgba(46, 118, 255, 0.2);
      }

      img {
        width: 630rem;
        height: 354rem;
        border-radius: 20rem;
      }

      .content {
        width: 632rem;
        text-align: left;
        font-weight: 600;
        font-size: 16rem;
        line-height: 22rem;
        font-family: PingFangSC-Medium;

        .title {
          font-weight: 600;
          font-size: 32rem;
          color: #414141;
          line-height: 48rem;
          margin-top: 16rem;
        }

        .detail {
          font-weight: 400;
          font-size: 20rem;
          color: #414141;
          line-height: 30rem;
          margin-top: 6rem;
          font-family: PingFangSC-Regular;
        }

        &>div:last-child {
          font-weight: 400;
          font-size: 20rem;
          color: #B2B2B2;
          line-height: 30rem;
          margin-top: 8rem;
        }
      }
    }
  }
}
</style>