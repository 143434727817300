<template>
  <div class="industry">
    <div class="title-font-style">工业解决方案</div>
    <div class="illustrate">
      <div>使用XR技术模拟复杂机械设备的装配、调试、维修维护等专业操作培训，提高员工培训效率，降低操作失误率，减少安全风险，为企业降本增效。</div>
    </div>
    <div class="subtitle">行业痛点</div>
    <div>
      <div class="disadvantage">伴随着工业领域的产业技术升级，数字化工厂的建设对企业传统培训做业流程提出新的挑战。工作流程复杂，设备操作复杂的特定行业需要高效的标准化的操作流程培训与考核。</div>
    </div>
    <div class="hytd-box">
      <div class="hytd">
        <div v-for="(item, index) in prospects" :key="index">
          <div class="images">
            <img :src="item.url" />
          </div>
          <div>
            <div class="title">
              <img :src="item.src" />
              <span>{{ item.title }}</span>
            </div>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="subtitle">方案优势</div>
    <div class="fays">
      <img :src="loadUrl.fays_bg"/>
      <div>
        <div>
          <div class="title">
            <img :src="loadUrl.xiaolv" />
            <span>培训效率提升</span>
          </div>
          <div>XR可视化培训相比传统方式，更为直观易学，有效缩短员工培训时间，提高培训效率。</div>
        </div>
        <div>
          <div class="title">
            <img src="@/assets/images/jjfa/caozuo.png" />
            <span>操作流程规范</span>
          </div>
          <div>AR标准化操作流程指导，配置可视化参数，规范员工作业过程，提升员工工作质量，从而提高企业效率。</div>
        </div>
      </div>
      <div>
        <div>
          <div class="title">
            <img src="@/assets/images/solution/hudong.png" />
            <span>互动参与感强</span>
          </div>
          <div>基于实际环境、设备，以三维化多媒体素材为主，过程多样化智能交互，减少对传统纸质手册和专家的依赖，并结合具体情况提供更适合的情景培训。</div>
        </div>
        <div>
          <div class="title">
            <img :src="loadUrl.zichan" />
            <span>沉淀数字资产</span>
          </div>
          <div>培训过程中不断积累沉淀企业数字化内容，形成企业自有知识库。</div>
        </div>
      </div>
    </div>
    <div class="yycj">
      <div class="subtitle">应用场景</div>
      <div class="yycj-content">
        <div>
          <img :src="loadUrl.yycj_img3" class="left-radius" />
          <div>
            <div class="title">
              <img :src="loadUrl.yycj_icon3" />
              <span>新员工培训</span>
            </div>
            <div class="content">模拟真实工作环境下的专项技能参数化模拟培训及考核。</div>
          </div>
        </div>
        <div>
          <div>
            <div class="title">
              <img :src="loadUrl.yycj_icon4" class="right-radius" />
              <span>消防安全培训</span>
            </div>
            <div class="content">对企业员工进行消防安全培训，增强消防安全意识，进一步提高消防安全素质和自防、自救能力。</div>
          </div>
          <img :src="loadUrl.yycj_img4" />
        </div>
      </div>
    </div>
    <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div
          @click="toOtherPath(`/case_detail?id=${casesList.id}&pathName=解决方案,工业解决方案,应用案例`, ['解决方案', '工业解决方案', '应用案例'])">
          <img :src="casesList.ossPath" />
          <div class="content">
            <div class="title">{{ casesList.title }}</div>
            <div>{{ casesList.subTitle }}</div>
            <div class="detail">
              {{ casesList.introduction }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const store = useStore()
const router = useRouter()
const casesList: any = ref({})
const prospects: any = ref(
  [
    {
      title: '互动性较弱',
      content: '传统培训模式互动性和参与感较弱，缺乏场景感，无法达到真实工作环境下的实操模拟培训及考核要求。',
      url: require('@/assets/images/solution/disadvantage1.png'),
      src: require('@/assets/images/solution/disadvantage-icon1.png')
    },
    {
      title: '数字化不足',
      content: '传统培训模式在实际操作过程中缺少量化参数及考核数据，无法形成企业组织培训数字化资产。',
      url: require('@/assets/images/solution/disadvantage2.png'),
      src: require('@/assets/images/solution/disadvantage-icon2.png')
    },
    {
      title: '培训成本高',
      content: '高危行业传统新员工操作培训成本高并带有安全风险，培训成本高，培训效率亟待提高。',
      url: require('@/assets/images/solution/disadvantage3.png'),
      src: require('@/assets/images/solution/disadvantage-icon3.png')
    }
  ]
)

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(async () => {
  selectCommonElement({ columnId: 4 }).then((res: any) => {
    casesList.value = [...res].filter(e => e.industry == 2)[0] || {}
  })

  const xiaolv: any = await asynchronousRetrieval('nfznxr-images/jjfa/xiaolv.png');
  const zichan: any = await asynchronousRetrieval('nfznxr-images/jjfa/zichan.png');
  const yycj_img3: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img3.png');
  const yycj_icon3: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-icon3.png');
  const yycj_icon4: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-icon4.png');
  const yycj_img4: any = await asynchronousRetrieval('nfznxr-images/jjfa/yycj-img4.png');
  const fays_bg: any = await asynchronousRetrieval('nfznxr-images/jjfa/fays-bg.png');
  loadUrl.value = { xiaolv, zichan, yycj_img3, yycj_icon3, yycj_icon4, yycj_img4, fays_bg }
})
</script>
<style scoped lang="less">
.industry {
  background-color: #F7F7F7;
  padding-top: 100rem;
  overflow: hidden;

  .illustrate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40rem 0 80rem;

    &>div {
      font-weight: 400;
      font-size: 18rem;
      color: #333333;
      width: 661rem;
      line-height: 30rem;
    }
  }

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 28rem;
    color: #333333;
    line-height: 40rem;
    display: inline-block;
    padding: 0 10rem;

    &::after {
      content: '';
      width: 100%;
      height: 13rem;
      background: rgba(215, 24, 25, 0.21);
      position: absolute;
      left: 0;
      bottom: 3rem;
    }
  }

  .disadvantage {
    padding: 20rem 40rem;
    width: 1204rem;
    display: inline-block;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 8rem;
    margin: 60rem 0 40rem;
    color: #333;
  }

  .hytd-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 80rem;

    .hytd {
      width: 1204rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &>div {
        width: 394rem;
        font-size: 0;
        box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 8rem;
        overflow: hidden;

        &>div {
          padding: 30rem 29rem;
          font-weight: 400;
          font-size: 16rem;
          color: #5C5C5C;
          line-height: 30rem;
          text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
          text-align: left;
          height: 200rem;
          box-sizing: border-box;
          background: #FFFFFF;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30px;
            text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
            font-family: PingFangSC-Medium;
            margin-bottom: 15rem;

            img {
              width: 47rem;
              height: 46rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }

      .images {
        width: 393rem;
        height: 330rem;
        background: #F2F4FA;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0;
        font-size: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .fays {
    position: relative;
    width: 100%;
    height: 530rem;
    margin-top: 80rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100rem;
    text-align: left;

    &>img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index:0;
    }

    &>div {
      width: 513rem;
      height: 410rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 0 5rem;

      &>div {
        width: 513rem;
        height: 200rem;
        background-color: #fff;
        border-radius: 8rem;
        backdrop-filter: blur(10rem);
        font-weight: 400;
        font-size: 16rem;
        color: #333333;
        line-height: 30rem;
        padding: 20rem;
        box-sizing: border-box;
      }

      .title {
        font-weight: 600;
        font-size: 22rem;
        color: #333333;
        line-height: 30rem;
        font-family: PingFangSC-Medium;

        img {
          width: 52rem;
          height: 50rem;
          vertical-align: middle;
        }

        span {
          vertical-align: middle;
        }
      }

      &>div>div:last-child {
        padding: 10rem;
      }
    }
  }

  .yycj {
    background-color: #fff;
    padding-top: 60rem;

    .yycj-content {
      margin-top: 80rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &>div {
        width: 1201rem;
        background: #F7F7F7;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8rem;

        &>img {
          width: 978rem;
          height: 280rem;
        }

        &>div {
          display: inline-block;
          width: 222rem;
          height: 280rem;
          text-align: left;
          padding: 40rem 20rem;
          box-sizing: border-box;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30rem;
            margin-bottom: 10rem;

            img {
              width: 50rem;
              height: 50rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }

          .content {
            padding: 8rem;
            color: #333333;
            font-size: 16rem;
            line-height: 30rem;
            box-sizing: border-box;
            text-align: justify;
          }
        }
      }

      &>div:last-child {
        margin-top: 10rem;
      }
    }
  }

  .yyal {
    padding-top: 80rem;
    background-color: #fff;
    overflow: hidden;

    .yyal-content {
      margin: 80rem 0 40rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 1040rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 60rem;
        padding: 20rem;
        border-radius: 8rem;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(215, 24, 25, 0.2), 0px 0px 4px 0px rgba(215, 24, 25, 0.2);
        }

        img {
          width: 400rem;
          height: 224rem;
        }

        .content {
          width: 607rem;
          height: 224rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 24rem;
            color: #0A0A0A;
            line-height: 33px;
            margin: 8rem 0 9rem;
          }

          .detail {
            font-weight: 400;
            font-size: 14rem;
            color: #333333;
            line-height: 30rem;
            margin-top: 20rem;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }
  }
}
</style>