<template>
  <div class="job-news-detail">
    <div class="page-list">
      <span>关于我们</span>
      <span class="next-icon"></span>
      <span>加入我们</span>
      <span class="next-icon"></span>
      <span>岗位详情</span>
    </div>
    <div class="job-title">{{ jobName }}</div>
    <div class="news-detail">
      <div></div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { getCommonElementById, getStaticHtml, getOssUrls } from '@/api'
import { findSequences, findSequences2 } from '@/utils'

const jobName = ref('')

onMounted(() => {
  const path: any = JSON.parse(window.sessionStorage.getItem('path') || '{}');
  getCommonElementById({ id: path.query?.id }).then((res: any) => {
    jobName.value = res.name;
    if (res.relatedHtmlId) {
      getStaticHtml({ id: res.relatedHtmlId }).then((html: any) => {
        const resultKeys = findSequences(html);
        const resultSources = findSequences2(html);
        getOssUrls(resultKeys).then((res: any) => {
          resultKeys.forEach((urlKey, i) => {
            html = html.replace(resultSources[i], `source src="${res[i]}`)
          });
          (document.querySelector('.news-detail div') as any).innerHTML = html
        })
      })
    }

  })
  if (document.documentElement && document.documentElement.scrollTop) {
    document.documentElement.scrollTop = 0;
  } else if (document.body) {
    document.body.scrollTop = 0
  }
})
</script>
<style scoped lang="less">
.job-news-detail {
  width: 100%;
  font-size: 0;
  min-height: calc(100vh - 469rem);
  padding-top: 96rem;
  background-color: #F7F7F7;

  .page-list {
    text-align: left;
    padding-left: 28rem;

    span {
      font-weight: 400;
      font-size: 20rem;
      color: #414141;
      line-height: 30rem;
      margin-right: 20rem;
    }

    .next-icon {
      width: 15rem;
      height: 15rem;
      display: inline-block;
      background-image: url(@/assets/images/solution/left-c.png);
      background-size: 100% 100%;
      transform: rotate(180deg);
    }

    &>span:last-child {
      color: #D81418;
    }
  }

  .job-title {
    font-weight: bold;
    font-size: 40rem;
    color: #5D5D5D;
    line-height: 58rem;
    margin: 40rem 0 24rem;
  }

  .news-detail>div {
    width: 680rem;
    background: #FFFFFF;
    border-radius: 8rem;
    display: inline-block;
    font-size: 14rem;
    padding: 10rem 24rem;
    box-sizing: border-box;
    margin-bottom: 32rem;
  }
}
</style>