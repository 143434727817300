<template>
  <div>
    <banner-c></banner-c>
    <div class="about-us">
      <div class="title-font-style-m">加入我们</div>
      <div class="job-details">
        <div v-for="(item, index) in jobDetails" :key="index"
          @click="toOtherPath(`/job_detail?id=${item.id}&pathName=关于我们,加入我们,岗位详情`, ['关于我们', '加入我们', '岗位详情'])">
          <div class="text-title">{{ item.name }}</div>
          <div>{{ item.introduction }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import BannerC from '@/components/BannerC.vue';

const store = useStore()
const jobDetails: any = ref([])
const router = useRouter()

const toOtherPath = (url: string, pageTitle: any) => {
  router.push(url);
  store.state.pageTitle = [...pageTitle]
}

onMounted(() => {
  selectCommonElement({ columnId: 7 }).then((res: any) => {
    jobDetails.value = [...res]
  })
  if (document.documentElement && document.documentElement.scrollTop) {
    document.documentElement.scrollTop = 0;
  } else if (document.body) {
    document.body.scrollTop = 0
  }
})
</script>
<style scoped lang="less">
.about-us {
  font-family: PingFangSC-Regular;
  min-height: calc(100vh - 889rem);
  background-color: #F7F7F7;
  padding-top: 40rem;

  .job-details {
    width: 100%;
    font-size: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24rem;
    padding-bottom: 32rem;

    &>div {
      width: 680rem;
      display: inline-block;
      margin-bottom: 16rem;
      vertical-align: top;
      border-radius: 8px;
      padding: 24rem;
      box-sizing: border-box;
      font-weight: 400;
      font-size: 19rem;
      color: #414141;
      background-color: #fff;
      line-height: 1.5;

      .text-title {
        font-weight: 600;
        font-size: 32rem;
        margin-bottom: 10rem;
      }
    }
  }
}
</style>